import {
    Box,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { BaseURL } from "../../constants/Baseurl";
import ActionButton from "../FilterComponents/ActionButton";
import CompanySelector from "../FilterComponents/CompanySelector";
import { CaseContext } from "../../context/CaseContext";
import { Authorization_header } from "../../utils/helper/Constant";
import CountrySelect from "../Contacts/ContactModalDropdowns/CountrySelect";
// import CaseOwnerSelecter from "./CaseOwnerSelector";

const locations = ["Canada", "USA", "United Kingdom"];
const CaseFilter = ({ clientData, getCaseSortParams, CaseSort }) => {
    const {
        caseFilterState,
        setCaseFilterState,
        clearCaseFilterTrigger,
    } = useContext(CaseContext);
    const [company, setCompany] = useState(caseFilterState.company);
    const [countryName, setBillingCountry] = useState(caseFilterState.countryName);
    const [caseOwnerName, setCaseOwnerName] = useState(caseFilterState.caseOwnerName);
    const [billingCountryList, setBillingCountryList] = useState([]);
    const [caseOwnerList, setCaseOwnerList] = useState([]);
    const [sortField, setSortField] = useState("");
    const [sortOrder, setSortOrder] = useState("");
    const [isExpanded, setIsExpanded] = useState(false);
    const [hasFetchedData, setHasFetchedData] = useState(false);
    useEffect(() => {
        setCaseFilterState({
            ...caseFilterState,
            companyId: [
                clientData?.find((client) => client?.company === company)
                    ?.companyId,
            ],
            company: company,
        });
    }, [company]);

    useEffect(() => {
        setCaseFilterState({
            ...caseFilterState,
            countryName,
            countryId: [
                billingCountryList?.find((proj) => proj?.countryName === countryName)?.countryId,
            ],
            countryName: countryName,
        });
    }, [countryName]);
    useEffect(() => {
        setCaseOwnerName({
            ...caseFilterState,
            caseOwnerName,
            countryId: [
                caseOwnerList?.find((proj) => proj?.caseOwnerName === caseOwnerName)?.countryId,
            ],
            caseOwnerName: caseOwnerName,
        });
    }, [caseOwnerName]);

    useEffect(() => {
        getCaseSortParams({ sortField: sortField, sortOrder: sortOrder });
    }, [sortField, sortOrder]);

    const fetchData = async () => {
        try {
            if (caseFilterState?.companyId) {
                const response3 = await axios.get(
                    `${BaseURL}/api/v1/company/${localStorage.getItem("userid")}/get-companys-billing-country`, Authorization_header()
                );
                setBillingCountryList(response3.data.data);
                setHasFetchedData(true);
            } else {
                console.error("companyId not available in data object");
            }
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        fetchData();
    }, [caseFilterState?.companyId]);

    useEffect(() => {
        if (clearCaseFilterTrigger) {
            setCompany("");
            setBillingCountry("");
            setCaseFilterState({
                ...caseFilterState,
                companyId: [],
                company: "",
                countryName: "",
            });
        }
    }, [clearCaseFilterTrigger]);
    const handleAccordionChange = (event, isExpanded) => {
        setIsExpanded(isExpanded);
        if (isExpanded && !hasFetchedData) {
            fetchData();
        }
    };

    useEffect(() => {
        company && setCaseFilterState({
            ...caseFilterState,
            company: company,
        })
    }, [company]);

    useEffect(() => {
        sortField && setCaseFilterState({
            ...caseFilterState,
            sortField: sortField
        })
    }, [sortField]);

    useEffect(() => {
        sortOrder && setCaseFilterState({
            ...caseFilterState,
            sortOrder: sortOrder
        })
    }, [sortOrder]);

    return (
        <Box>
            <CompanySelector
                clients={clientData}
                company={company}
                setCompany={setCompany}
            />
            <CountrySelect
                countryName={countryName}
                billingCountryList={billingCountryList}
                setBillingCountry={setBillingCountry}
            />

            {/* <CaseOwnerSelecter
                caseOwnerName={caseOwnerName}
                caseOwnerList={caseOwnerList}
                setCaseOwnerName={setCaseOwnerName}
            /> */}
            <Accordion
                sx={{ maxWidth: '100%', overflowX: 'hidden' }}
                expanded={isExpanded}
                onChange={handleAccordionChange}
            >

                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography>Sorts</Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ overflowX: 'hidden' }}>
                    {/* Sort Field Selector */}
                    <FormControl
                        fullWidth
                        margin="normal"
                    // variant="outlined" // To match existing MUI styling of similar dropdowns
                    >
                        <InputLabel>Sort Field</InputLabel>
                        <Select
                            sx={{ width: '100%' }}
                            label="Sort Field"
                            value={sortField}
                            onChange={(e) => setSortField(e.target.value)}
                        >
                            {/* <MenuItem value="fiscalYear">Fiscal Year</MenuItem>
                <MenuItem value="totalExpense">Total Expense</MenuItem>
                <MenuItem value="rndExpense">R&D Expense</MenuItem>
                <MenuItem value="rndPotential">R&D Potential</MenuItem> */}
                            <MenuItem value="createdTime">Created Time</MenuItem>
                        </Select>
                    </FormControl>

                    {/* Sort Order Selector */}
                    <FormControl
                        fullWidth
                        margin="normal"
                        variant="outlined" // To match existing MUI styling of similar dropdowns
                    >
                        <InputLabel>Sort Order</InputLabel>
                        <Select
                            label="Sort Order"
                            value={sortOrder}
                            onChange={(e) => setSortOrder(e.target.value)}
                            sx={{ width: '100%' }}
                        >
                            <MenuItem value="ascending">Ascending</MenuItem>
                            <MenuItem value="descending">Descending</MenuItem>
                        </Select>
                    </FormControl>
                    <Box sx={{ display: "flex", justifyContent: "flex-end", padding: "0%" }}>
                        <ActionButton
                            label="Apply"
                            color="#00A398"
                            onClick={CaseSort} />
                    </Box>
                </AccordionDetails>
            </Accordion>
        </Box>
    );
}

export default CaseFilter;