import {
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { TimesheetContext } from "../../context/TimesheetContext";
import CompanySelector from "../FilterComponents/CompanySelector";
import MonthSelector from "../FilterComponents/MonthSelector.";
import SliderInput from "../FilterComponents/SliderInput";
import { MonthNames } from "../../constants/Months";
import ActionButton from "../FilterComponents/ActionButton";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AccYearSelector from "../FilterComponents/AccYearSelector";

function TimesheetFilters({ clientData, getTimeSheetSortParams, TimeSheetSort }) {
  const {
    timesheetFilterState,
    setTimesheetFilterState,
    clearTimesheetFilterTrigger,
  } = useContext(TimesheetContext);
  const [company, setCompany] = useState(timesheetFilterState.company);
  const [accYear, setAccYear] = useState(timesheetFilterState.accYear);
  const [sortField, setSortField] = useState(timesheetFilterState?.sortField);
  const [sortOrder, setSortOrder] = useState(timesheetFilterState?.sortOrder);

  const handleFilterChange = (field) => (event, newValue) => {
    const value = newValue ?? event.target.value;
    if (field === "sortField") {
      setSortField(value?.props?.value);
    }
    if (field === "sortOrder") {

      setSortOrder(value?.props?.value);
    }
    if (field === "sortField" || field === "sortOrder") {
      setTimesheetFilterState({
        ...timesheetFilterState,
        [field]: value?.props?.value
      })
    } else {
      setTimesheetFilterState({
        ...timesheetFilterState,
        [field]: value,
      });
    }
  };

  useEffect(() => {
    getTimeSheetSortParams({ sortField: sortField, sortOrder: sortOrder });

  }, [sortField, sortOrder])

  useEffect(() => {
    setTimesheetFilterState({
      ...timesheetFilterState,
      sortOrder: sortOrder
    })
  }, [sortOrder])

  useEffect(() => {
    setTimesheetFilterState({
      ...timesheetFilterState,
      sortOrder: sortField
    })
  }, [sortField])

  useEffect(() => {
    setTimesheetFilterState({
      ...timesheetFilterState,
      companyId: [
        clientData?.find((client) => client?.companyName === company)
          ?.companyId,
      ],
      company: company,
    });
  }, [company]);
  useEffect(() => {
    setTimesheetFilterState({
      ...timesheetFilterState,
      accountingYear: accYear === "" ? [] : [accYear],
      accYear: accYear,
    });
  }, [accYear]);
  // useEffect(() => {
  //   setTimesheetFilterState({
  //     ...timesheetFilterState,
  //     month: [monthName],
  //     monthName: monthName,
  //   });
  // }, [monthName]);
  useEffect(() => {
    if (clearTimesheetFilterTrigger) {
      setCompany("");
      setAccYear("");
      setSortField("");
      setSortOrder("");
      setTimesheetFilterState({
        ...timesheetFilterState,
        companyId: [],
        company: "",
        accountingYear: [],
        accYear: "",
        totalhours: [0, 2000000],
        sortOrder: "",
        sortField: "",
      });
    }
  }, [clearTimesheetFilterTrigger]);

  return (
    <Box>
      <CompanySelector
        clients={clientData}
        company={company}
        setCompany={setCompany}
      />
      <AccYearSelector accountingYear={accYear} setAccountingYear={setAccYear} />

      <SliderInput
        minWidth={220}
        label="Total Hours"
        value={timesheetFilterState?.totalhours}
        onChange={handleFilterChange("totalhours")}
        initialMin={0}
        initialMax={2000000}
      />
      <Accordion sx={{ maxWidth: '100%', overflowX: 'hidden', mt: "50px" }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>Sorts</Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ overflowX: 'hidden' }}>
          {/* Sort Field Selector */}
          <FormControl
            fullWidth
            margin="normal"
          // variant="outlined" // To match existing MUI styling of similar dropdowns
          >
            <InputLabel>Sort Field</InputLabel>
            <Select
              sx={{ width: '100%' }}
              label="Sort Field"
              value={timesheetFilterState?.sortField}
              onChange={handleFilterChange("sortField")}
            >
              <MenuItem value="fiscalYear">Fiscal Year</MenuItem>
              <MenuItem value="totalHours">Total Hours</MenuItem>
              <MenuItem value="uploadedOn">Uploaded On</MenuItem>
              {/* <MenuItem value="rndPotential">R&D Potential</MenuItem>
                <MenuItem value="createdTime">Created Time</MenuItem> */}
            </Select>
          </FormControl>

          {/* Sort Order Selector */}
          <FormControl
            fullWidth
            margin="normal"
            variant="outlined" // To match existing MUI styling of similar dropdowns
          >
            <InputLabel>Sort Order</InputLabel>
            <Select
              label="Sort Order"
              value={timesheetFilterState?.sortOrder}
              onChange={handleFilterChange("sortOrder")}
              sx={{ width: '100%' }}
            >
              <MenuItem value="ascending">Ascending</MenuItem>
              <MenuItem value="descending">Descending</MenuItem>
            </Select>
          </FormControl>
          {/* <Box sx={{ display: "flex", justifyContent: "flex-end", padding: "0%" }}>
            <ActionButton
              label="Apply"
              color="#00A398"
              onClick={TimeSheetSort} />
          </Box> */}
        </AccordionDetails>
      </Accordion >
    </Box >
  );
}

export default TimesheetFilters;
