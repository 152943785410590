import React from "react";
import FilterCustomAutocomplete from "../../Common/FilterCustomAutocomplete";

function CountrySelect({ countryName, billingCountryList, setBillingCountry, }) {
  return (
    <>
      <FilterCustomAutocomplete
        label=""
        placeholder="Select Country"
        options={billingCountryList?.map((countryName) => countryName) ?? []}
        value={countryName}
        onChange={(event, newValue) => {
          setBillingCountry(newValue ?? "");
        }}
        inputValue={countryName}
        onInputChange={(event, newInputValue) => {
          setBillingCountry(newInputValue ?? "");
        }}
        heading={"Country"}
      />
    </>
  );
}

export default CountrySelect;
