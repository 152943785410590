import { Box } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { ContactContext } from "../../context/ContactContext";
import { FilterListContext } from "../../context/FiltersListContext";
import CompanySelector from "../FilterComponents/CompanySelector";
import TitleSelector from "../FilterComponents/TitleSelector";
import { BaseURL } from "../../constants/Baseurl";
import axios from "axios";
import { Authorization_header } from "../../utils/helper/Constant";

function ContactFilters() {
  const {
    contactFilterState,
    setContactFilterState,
    clearContactFilterCounter
  } = useContext(ContactContext);
  const { clientList } = useContext(FilterListContext);

  const [company, setCompany] = useState(contactFilterState.company);
  const [employementType, setEmployeeTitle] = useState(contactFilterState.employementType);
  const [employeeTitleList, setEmployeetitleList] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${BaseURL}/api/v1/contacts/${localStorage.getItem("userid")}/get-contacts-roles`,
          Authorization_header()
        );
        setEmployeetitleList(response.data.data);
      } catch (error) {
        console.error("Error fetching employee title list:", error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const selectedCompanyId = clientList?.find(client => client?.companyName === company)?.companyId;
    const selectedEmployeeId = employeeTitleList?.find(emp => emp?.employeeTitleName === employementType)?.employeeId;

    setContactFilterState(prevState => ({
      ...prevState,
      companyId: selectedCompanyId ? [selectedCompanyId] : [],
      employeeId: selectedEmployeeId ? [selectedEmployeeId] : [],
      company: company,
      employementType: employementType,
    }));
  }, [company, employementType, clientList, employeeTitleList]);

  useEffect(() => {
    if (clearContactFilterCounter) {
      setCompany("");
      setEmployeeTitle("");
      setContactFilterState(prevState => ({
        ...prevState,
        companyId: [],
        company: "",
        employementType: "",
      }));
    }
  }, [clearContactFilterCounter]);

  return (
    <Box>
      <CompanySelector
        clients={clientList}
        company={company}
        setCompany={setCompany}
      />
      <TitleSelector
        employementType={employementType}
        employeeTitleList={employeeTitleList}
        setEmployeeTitle={setEmployeeTitle}
      />
    </Box>
  );
}

export default ContactFilters;
