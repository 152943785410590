import React from "react";
import FilterCustomAutocomplete from "../Common/FilterCustomAutocomplete";

const TitleSelector = ({ employementType, employeeTitleList, setEmployeeTitle }) => (
  <>
    <FilterCustomAutocomplete
      label=""
      placeholder="Select Title"
      options={employeeTitleList?.map((employementType) => employementType) ?? []}
      value={employementType}
      onChange={(event, newValue) => {
        setEmployeeTitle(newValue ?? "");
      }}
      inputValue={employementType}
      onInputChange={(event, newInputValue) => {
        setEmployeeTitle(newInputValue ?? "");
      }}
      heading={"Title"}
    />
  </>
);

export default TitleSelector;
