import axios from "axios";
import React, { createContext, useState } from "react";
import usePinnedData from "../components/CustomHooks/usePinnedData";
import { BaseURL } from "../constants/Baseurl";
import { Authorization_header } from "../utils/helper/Constant";
import { useAuthContext } from "./AuthProvider";
import toast from "react-hot-toast";

export const ClientContext = createContext();

export const ClientProvider = ({ children }) => {
  const { pinnedObject } = usePinnedData();
  const [clientData, setClientData] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [clientFilters, setClientFilters] = useState({
    billingCountry: "",
    projectsCount: [1, 2000000],
    totalProjectCost: [1, 2000000],
    totalRnDCost: [1, 2000000],
    sortOrder: "",
    sortField: ""
  });
  const [clearClientFilterTrigger, setClearClientFilterTrigger] =
    useState(false);
  const [isClientFilterApplied, setIsClientFilterApplied] = useState(false);
  const [currentState, setCurrentState] = useState(pinnedObject?.CLNT || "All");
  const { logout } = useAuthContext();

  const triggerClientClearFilters = () => {
    setClearClientFilterTrigger((prev) => !prev);
  };

  function getAccessToken() {
    const tokens = localStorage.getItem('tokens');
    const token_obj = JSON.parse(tokens);
    return token_obj?.accessToken || '';
  }

  const applySort = async ({ sortField, sortOrder }) => {
    const url = `${BaseURL}/api/v1/company/${localStorage.getItem("userid")}/get-company-list`;
    try {
      toast.loading("Fetching client data");
      const config = {
        headers: {
          Authorization: `Bearer ${getAccessToken()}`,
        },
        params: {
          sortField: sortField,
          sortOrder: sortOrder,
        },
      };
      const response = await axios.get(url, config)
      toast.dismiss();
      setClientData(response?.data?.data);
      toast.success(response?.data?.message || "Succesfully fetched data");
    } catch (error) {
      toast.dismiss();
      toast.error("Error in fetching client data");
      console.error("Error in fetching client data : ", error);
    }
  }

  const fetchClientData = async (options = {}) => {
    setLoading(true);
    const queryParams = new URLSearchParams();

    if (options.billingCountry) {
      queryParams.append("billingCountry", options.billingCountry);
    }
    if (options.minProjectsCount != null && options.minProjectsCount > 1)
      queryParams.append("minTotalProjects", options.minProjectsCount);
    if (options.maxProjectsCount != null && options.maxProjectsCount < 2000000)
      queryParams.append("maxTotalProjects", options.maxProjectsCount);

    if (options.minTotalExpense != null && options.minTotalExpense > 1)
      queryParams.append("minTotalExpense", options.minTotalExpense);
    if (options.maxTotalExpense != null && options.maxTotalExpense < 2000000)
      queryParams.append("maxTotalExpense", options.maxTotalExpense);

    if (options.minTotalRnDExpense != null && options.minTotalRnDExpense > 1)
      queryParams.append("minTotalRnDExpense", options.minTotalRnDExpense);
    if (options.maxTotalRnDExpense != null && options.maxTotalRnDExpense < 2000000)
      queryParams.append("maxTotalRnDExpense", options.maxTotalRnDExpense);

    if (currentState === "Recently Viewed")
      queryParams.append("recentlyViewed", true);
    if (options.sortField && options.sortOrder && options.sortField !== null && options.sortOrder !== null) {
      queryParams.append("sortField", options.sortField);
      queryParams.append("sortOrder", options.sortOrder);
    }

    const queryString = queryParams.toString();
    const url = `${BaseURL}/api/v1/company/${localStorage.getItem(
      "userid"
    )}/get-company-list${queryString ? `?${queryString}` : ""}`;
    try {
      const response = await axios.get(url, Authorization_header());
      setClientData(response?.data?.data);
      setLoading(false);
    } catch (error) {
      console.error("error :", error);
      if (error?.response?.data?.logout === true || error?.response?.data?.message === "session timed out") {
        toast.error("Session expired, you need to login again");
        logout();
      }
      setError(error.toString());
    } finally {
      setLoading(false);
    }
  };


  const contextValue = {
    clientData,
    loading,
    error,
    fetchClientData,
    applySort,
    isClientFilterApplied,
    setIsClientFilterApplied,
    clearClientFilterTrigger,
    clientFilters,
    setClientFilters,
    triggerClientClearFilters,
    setCurrentState,
    currentState,
  };

  return (
    <ClientContext.Provider value={contextValue}>
      {children}
    </ClientContext.Provider>
  );
};
