import {
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ActionButton from "../FilterComponents/ActionButton";
import React, { useContext, useEffect, useState } from "react";
import CompanySelector from "../FilterComponents/CompanySelector";
import ProjectSelector from "../FilterComponents/ProjectSelector";
import DocumentTypeSelector from "../FilterComponents/DocumentTypeSelector";
import axios from "axios";
import { BaseURL } from "../../constants/Baseurl";
import { DocumentContext } from "../../context/DocumentContext";
import { Authorization_header } from "../../utils/helper/Constant";

function DocumentFilters({ documentClientData, documentType, getDocumentSortParams, DocumentSort }) {
  const { docFilterState, setDocFilterState, isDocFilterApplied, clearFilterTrigger } =
    useContext(DocumentContext);
  const [company, setCompany] = useState(docFilterState.company);
  const [project, setProject] = useState(docFilterState.project);
  const [doc, setDoc] = useState(docFilterState.document);
  const [companyProjects, setCompanyProjects] = useState(null);
  const [sortField, setSortField] = useState("");
  const [sortOrder, setSortOrder] = useState("");
  const [isAccordionOpen, setIsAccordionOpen] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [hasFetchedData, setHasFetchedData] = useState(false);

  const clearAllFilters = () => {
    setCompany("");
    setProject("");
    setDoc("");
    setSortField("");
    setSortOrder("");
    setDocFilterState({
      companyId: [],
      projectId: "",
      document: "",
      company: "",
      project: "",
      sortField: "",
      sortOrder: "",
    });
  };

  useEffect(() => {
    setDocFilterState({
      ...docFilterState,
      companyId: [
        documentClientData?.find((client) => client?.companyName === company)
          ?.companyId,
      ],
      projectId: companyProjects?.find((proj) => proj?.projectName === project)
        ?.projectId,
      document: doc,
      company: company,
      project: project,
    });
  }, [company, project, doc]);

  useEffect(() => {
    getDocumentSortParams({ sortField: sortField, sortOrder: sortOrder });
  }, [sortField, sortOrder])

  useEffect(() => {
    setDocFilterState({
      ...docFilterState,
      sortField: sortField,
    })
  }, [sortField])

  useEffect(() => {
    setDocFilterState({
      ...docFilterState,
      sortOrder: sortOrder,
    });
  }, [sortOrder])

  const handleFilterChange = () => {

  }

  const fetchData = async () => {
    try {
      if (docFilterState?.companyId) {
        const response3 = await axios.get(
          `${BaseURL}/api/v1/company/${localStorage.getItem("userid")}/${docFilterState?.companyId
          }/get-projects-by-company`, Authorization_header()
        );
        setCompanyProjects(response3.data.data);
        setHasFetchedData(true);
      } else {
        console.error("companyId not available in data object");
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [docFilterState?.companyId]);

  useEffect(() => {
    if (clearFilterTrigger) {
      clearAllFilters();
    }
  }, [clearFilterTrigger]);
  const handleAccordionChange = (event, isExpanded) => {
    setIsExpanded(isExpanded);
    if (isExpanded && !hasFetchedData) {
      fetchData();
    }
  };

  return (
    <Box>
      <CompanySelector
        clients={documentClientData}
        company={company}
        setCompany={setCompany}
      />
      <ProjectSelector
        companyProjects={companyProjects}
        project={project}
        setProject={setProject}
      />
      <DocumentTypeSelector docType={documentType} doc={doc} setDoc={setDoc} />
      <Accordion
        sx={{ maxWidth: '100%', overflowX: 'hidden' }}
        // expanded={isAccordionOpen}
        expanded={isExpanded}
        onChange={handleAccordionChange}
      // onChange={() => {
      //   setIsAccordionOpen(!isAccordionOpen);
      //   if (isAccordionOpen) {
      //     clearAllFilters();
      //   }
      // }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>Sorts</Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ overflowX: 'hidden' }}>
          {/* Sort Field Selector */}
          <FormControl
            fullWidth
            margin="normal"
          >
            <InputLabel>Sort Field</InputLabel>
            <Select
              sx={{ width: '100%' }}
              label="Sort Field"
              value={sortField}
              onChange={(e) => setSortField(e.target.value)}
            >
              <MenuItem value="uploadedOn">Uploaded On</MenuItem>
              <MenuItem value="rndPotential">R&D Potential</MenuItem>
            </Select>
          </FormControl>

          {/* Sort Order Selector */}
          <FormControl
            fullWidth
            margin="normal"
          >
            <InputLabel>Sort Order</InputLabel>
            <Select
              label="Sort Order"
              value={sortOrder}
              onChange={(e) => setSortOrder(e.target.value)}
              sx={{ width: '100%' }}
            >
              <MenuItem value="ascending">Ascending</MenuItem>
              <MenuItem value="descending">Descending</MenuItem>
            </Select>
          </FormControl>
          {/* <Box sx={{ display: "flex", justifyContent: "flex-end", padding: "0%" }}>
            <ActionButton
              label="Apply"
              color="#00A398"
              onClick={DocumentSort} />
          </Box> */}
        </AccordionDetails>
      </Accordion>
    </Box>
  );
}

export default DocumentFilters;
