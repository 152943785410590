import { Paper, Table, TableContainer } from "@mui/material";
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import CustomPagination from "../../components/Common/CustomPagination";
import TableHeader2 from "../../components/Common/TableHeader2";
import TableIntro from "../../components/Common/TableIntro";
import DocumentsModal from "../../components/Documents/DocumentsModal";
import DocumentsTableBody from "../../components/Documents/DocumentsTableBody";
import { BaseURL } from "../../constants/Baseurl";
import { DocumentContext } from "../../context/DocumentContext";
import { FilterListContext } from "../../context/FiltersListContext";
import { areFiltersApplied } from "../../utils/helper/AreFiltersApplied";
import { formattedDate } from "../../utils/helper/FormatDatetime";
import { getTimeDifference } from "../../utils/helper/UpdateTimeDifference";
import { useHasAccessToFeature } from "../../utils/helper/HasAccessToFeature";
import { Authorization_header, token_obj } from "../../utils/helper/Constant";
import usePinnedData from "../../components/CustomHooks/usePinnedData";

const tableData = {
  columns: [

    "Document Name",
    "Account",
    "Category",
    "R&D Potential (%)",
    "Status",
    "Projects",
    "Uploaded On",
    "Uploaded By",
  ],
  rows: [
    {
      id: 1,
      name: "Attachment 01",
      category: "POC",
      relatedTo: "Project 1",
      uploadedOn: "12/12/2023",
      uploadedBy: "Prabhu Balakrishnan",
    },
    {
      id: 2,
      name: "Attachment 02",
      category: "POC",
      relatedTo: "Apple Inc.",
      uploadedOn: "19/12/2023",
      uploadedBy: "Prabhu Balakrishnan",
    },
    // ...add more rows as needed
  ],
};

const documentType = ["SOW", "Technical Documents"];

function Documents() {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(20);
  const [modalOpen, setModalOpen] = useState(false);
  const { clientList, fetchClientList } = useContext(FilterListContext);
  const [search, setSearch] = useState("");
  const [filteredRows, setFilteredRows] = useState([]);
  const [latestUpdateTime, setLatestUpdateTime] = useState("Just now");
  const [documentData, setDocumentdata] = useState(null);
  const { pinnedObject } = usePinnedData();
  const [pinStates, setPinStates] = useState({
    "All Documents": false,
    "Recently Viewed": false,
  });

  const { documents, setCurrentState, fetchDocuments, docFilterState, currentState } =
    useContext(DocumentContext);
  const totalPages = Math.ceil(filteredRows?.length / itemsPerPage);

  const handleChangePage = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleUploadClick = () => {
    setModalOpen(true);
  };

  useEffect(() => {
    const shouldFetchWithFilters =
      docFilterState.companyId.length > 0 ||
      docFilterState.projectId ||
      docFilterState.document;

    const options = {
      ...(docFilterState.companyId.length > 0 && { companyIds: docFilterState.companyId }),
      ...(docFilterState.projectId && { relationId: docFilterState.projectId }),
      ...(docFilterState.document && { documentType: docFilterState.document }),
    };

    fetchDocuments(shouldFetchWithFilters ? options : undefined);
  }, [currentState, Authorization_header]);

  const handleSelectedHeaderItem = (item) => {
    setCurrentState(item);
  };

  const appliedFilters = {
    Clients: docFilterState.company,
    Projects: docFilterState.project,
    DocumentType: docFilterState.document,
    SortField: docFilterState.sortField,
    SortOrder: docFilterState.sortOrder
  };

  const applyFiltersAndFetch = (filters) => {
    if (areFiltersApplied(appliedFilters) || filters?.sortField || filters?.sortOrder) {
      fetchDocuments(filters);
    } else {
      fetchDocuments();
      toast.error("Please select at least one filter.");
    }
  };


  useEffect(() => {
    fetchClientList();
    setCurrentState(
      pinnedObject?.DOCUMENTS === "RV" ? "Recently Viewed" : "All Documents"
    );
  }, [Authorization_header]);
  useEffect(() => {
    const updatedPinStates = {
      "All Documents": pinnedObject.DOCUMENTS === "ALL",
      "Recently Viewed": pinnedObject.DOCUMENTS === "RV",
    };
    setPinStates(updatedPinStates);
  }, [pinnedObject.DOCUMENTS]);


  const handleModalClose = () => {
    setModalOpen(false);
  };

  const handleChangeItemsPerPage = (items) => {
    setItemsPerPage(items);
    setCurrentPage(1);
  };

  let currentData = filteredRows?.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  // Ensuring currentData always has 20 items
  const placeholderRow = {};
  while (currentData?.length < itemsPerPage) {
    currentData.push(placeholderRow);
  }

  const handleSearch = (input) => {
    setSearch(input);
  };

  useEffect(() => {
    if (documents) {
      const filteredData = documents?.filter(
        (task) =>
          task?.relatedTo?.toLowerCase()?.includes(search?.toLowerCase()) ||
          task?.documentName?.toLowerCase()?.includes(search?.toLowerCase()) ||
          task?.documentType?.toLowerCase()?.includes(search?.toLowerCase()) ||
          task?.createdBy?.toLowerCase()?.includes(search?.toLowerCase()) ||
          task?.RnDScope?.toLowerCase()?.includes(search?.toLowerCase()) ||
          task?.status?.toLowerCase()?.includes(search?.toLowerCase()) ||
          formattedDate(task?.createdTime)
            ?.toLowerCase()
            ?.includes(search?.toLowerCase())
      );
      setFilteredRows(filteredData);
      setCurrentPage(1);
    }
  }, [documents, search]);

  useEffect(() => {
    const timeDifference = getTimeDifference(documents, "createdTime");
    setLatestUpdateTime(timeDifference);
  }, [documents]);

  const handleFormSubmit = async (formData) => {
    const apiUrl = `${BaseURL}/api/v1/documents/${localStorage.getItem(
      "userid"
    )}/upload-doc`;

    const formDataToSubmit = new FormData();
    formData.files?.forEach((file) => {
      formDataToSubmit.append("documents", file);
    });

    formDataToSubmit.append("companyId", formData.companyId);
    formDataToSubmit.append("relatedTo", formData.relatedTo);
    formDataToSubmit.append("relationId", formData.relationId);
    formDataToSubmit.append("docType", formData.doc);

    toast.loading("Uploading Documents...");
    try {
      const tokens = localStorage.getItem('tokens');
      const token_obj = JSON.parse(tokens);

      const response = await axios.post(apiUrl, formDataToSubmit, {
        headers: {
          "Content-Type": "multipart/form-data",
          'Authorization': `Bearer ${token_obj?.accessToken}`
        },
      });
      setDocumentdata(response?.data?.data);
      fetchDocuments();
      handleModalClose();
      toast.dismiss();
      toast.success(response?.data?.message || "The file has been uploaded successfully and is currently in the processing queue. You'll receive a notification once processing is complete.");
    } catch (error) {
      toast.dismiss();
      toast.error(error?.response?.data?.message || "Failed to upload document.")
    }
  };

  const isUpload = useHasAccessToFeature("F029", "P000000002");
  const isSearch = useHasAccessToFeature("F029", "P000000009");

  return (
    <>
      {useHasAccessToFeature("F029", "P000000008") && (
        <Paper
          sx={{
            display: "flex",
            width: "98.5%",
            mx: "auto",
            mt: 1,
            flexDirection: "column",
            borderRadius: "20px",
            mb: 3,
            boxShadow: "0px 3px 6px #0000001F",
          }}
        >
          <TableIntro
            heading={"All Documents"}
            btnName={"Upload"}
            page={"document"}
            totalItems={filteredRows?.length || 0}
            currentPage={currentPage}
            itemsPerPage={itemsPerPage}
            onUploadClick={handleUploadClick}
            onSearch={handleSearch}
            latestUpdateTime={latestUpdateTime?.difference}
            documentType={documentType}
            onApplyFilters={applyFiltersAndFetch}
            appliedFilters={appliedFilters}
            createPermission={isUpload}
            searchPermission={isSearch}
            onSelectedItem={handleSelectedHeaderItem}
          />
          <DocumentsModal
            open={modalOpen}
            handleClose={handleModalClose}
            handleSubmit={handleFormSubmit}
            type={"upload"}
            clients={clientList}
            docType={documentType}
            page="document"
          />
          <TableContainer
            sx={{
              maxHeight: "82vh",
              overflowY: "auto",
            }}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHeader2 tableData={tableData} page={"document"} />
              <DocumentsTableBody data={currentData} />
            </Table>
          </TableContainer>
          <CustomPagination
            currentPage={currentPage}
            totalPages={totalPages}
            changePage={handleChangePage}
            changeItemsPerPage={handleChangeItemsPerPage}
            minRows={20}
          />
        </Paper>
      )}
      <Toaster />
    </>
  );
}

export default Documents;
