import {
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { BaseURL } from "../../constants/Baseurl";
import { ProjectContext } from "../../context/ProjectContext";
import AccYearSelector from "../FilterComponents/AccYearSelector";
import CompanySelector from "../FilterComponents/CompanySelector";
import ActionButton from "../FilterComponents/ActionButton";
import { ClientContext } from "../../context/ClientContext";
import SliderInput from "../FilterComponents/SliderInput";
// import PortfolioSelector from "../FilterComponents/PortfolioSelector";

function ProjectsFilters({ clientData, getProjectSortParams, projectSort }) {
  const {
    projectFilterState,
    setProjectFilterState,
    clearProjectFilterTrigger,
  } = useContext(ProjectContext);
  const [company, setCompany] = useState(projectFilterState.company);
  const [project, setProject] = useState(projectFilterState.project);
  const [accYear, setAccYear] = useState(projectFilterState.accYear);

  // const [portfolioList, setPortfolioList] = useState([]);
  const [sortField, setSortField] = useState(projectFilterState.sortField);
  const [sortOrder, setSortOrder] = useState(projectFilterState.sortOrder);

  const handleFilterChange = (field) => (event, newValue) => {
    const value = newValue ?? event?.target?.value;
    if (field === "sortField") {
      setSortField(event.target.value);
    } else if (field === "sortOrder") {
      setSortOrder(event.target.value);
    }
    if (field === "sortField" || field === "sortOrder") {

      setProjectFilterState({
        ...projectFilterState,
        [field]: value?.props?.value,
      })
    }
    setProjectFilterState({
      ...projectFilterState,
      [field]: value,
    });
  };


  useEffect(() => {
    getProjectSortParams({ sortField: sortField, sortOrder: sortOrder })
  }, [sortField, sortOrder])

  useEffect(() => {
    setProjectFilterState({
      ...projectFilterState,
      companyId: [
        clientData?.find((client) => client?.companyName === company)
          ?.companyId,
      ],
      company: company,
    });
  }, [company]);

  // useEffect(() => {
  //   if (sortField != undefined && sortField) {
  //     ...projectFilterState,

  //   }
  // }, [sortField, sortOrder])

  // useEffect(() => {
  //   setProjectFilterState({
  //     ...projectFilterState,
  //     portfolioId: [
  //       portfolioList?.find((proj) => proj?.name === project)?.portfolioId,
  //     ],
  //     project: project,
  //   });
  // }, [project]);

  useEffect(() => {
    setProjectFilterState({
      ...projectFilterState,
      accountingYear: accYear === "" ? [] : [accYear],
      accYear: accYear,
    });
  }, [accYear]);

  useEffect(() => {
    setProjectFilterState({
      ...projectFilterState,
      sortField: sortField === "" ? "" : sortField
    })

  }, [sortField]);

  useEffect(() => {
    setProjectFilterState({
      ...projectFilterState,
      sortOrder: sortOrder,
    })
  }, [sortOrder]);
  useEffect(() => {
    if (clearProjectFilterTrigger) {
      setCompany("");
      setProject("");
      setAccYear("");
      setSortField("");
      setSortOrder("");
      setProjectFilterState({
        companyId: [],
        portfolioId: [],
        accountingYear: [],
        accYear: "",
        company: "",
        project: "",
        totalExpense: [0, 2000000],
        rndExpense: [0, 2000000],
        rndPotential: [0, 100],
        sortField: "",
        sortOrder: ""
      });
    }
  }, [clearProjectFilterTrigger]);

  return (
    <Box>
      <Box>
        <CompanySelector
          clients={clientData}
          company={company}
          setCompany={setCompany}
        />

        <AccYearSelector accountingYear={accYear} setAccountingYear={setAccYear} />
        <SliderInput
          initialMin={0}
          initialMax={2000000}
          value={projectFilterState?.totalExpense}
          onChange={handleFilterChange("totalExpense")}
          label="Total Expense"
          minWidth={220}
        />
        <SliderInput
          initialMin={0}
          initialMax={2000000}
          minWidth={220}
          label="R&D Expense"
          value={projectFilterState?.rndExpense}
          onChange={handleFilterChange("rndExpense")}
        />
        <SliderInput
          minWidth={220}
          label="R&D Potential"
          value={projectFilterState?.rndPotential}
          onChange={handleFilterChange("rndPotential")}
          min={0}
          max={100}
        />
      </Box>

      <Box sx={{ marginTop: "40px" }}>
        <Accordion sx={{ maxWidth: '100%', overflowX: 'hidden' }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>Sorts</Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ overflowX: 'hidden' }}>

            <FormControl
              fullWidth
              margin="normal"

            >
              <InputLabel>Sort Field</InputLabel>
              <Select
                sx={{ width: '100%' }}
                label="Sort Field"
                value={projectFilterState?.sortField}
                onChange={handleFilterChange("sortField")}
              >
                <MenuItem value="fiscalYear">Fiscal Year</MenuItem>
                <MenuItem value="totalExpense">Total Expense</MenuItem>
                <MenuItem value="rndExpense">R&D Expense</MenuItem>
                <MenuItem value="rndPotential">R&D Potential</MenuItem>
                <MenuItem value="createdTime">Created Time</MenuItem>
              </Select>
            </FormControl>

            {/* Sort Order Selector */}
            <FormControl
              fullWidth
              margin="normal"
              variant="outlined" // To match existing MUI styling of similar dropdowns
            >
              <InputLabel>Sort Order</InputLabel>
              <Select
                label="Sort Order"
                value={projectFilterState?.sortOrder}
                onChange={handleFilterChange("sortOrder")}
                sx={{ width: '100%' }}
              >
                <MenuItem value="ascending">Ascending</MenuItem>
                <MenuItem value="descending">Descending</MenuItem>
              </Select>
            </FormControl>
            {/* <Box sx={{ display: "flex", justifyContent: "flex-end", padding: "0%" }}>
              <ActionButton
                label="Apply"
                color="#00A398"
                onClick={projectSort} />
            </Box> */}
          </AccordionDetails>
        </Accordion>
      </Box>
    </Box>
  );
}

export default ProjectsFilters;

