import { CircularProgress, Table, TableContainer, Box, Button } from "@mui/material";
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import { BaseURL } from "../../constants/Baseurl";
import { FilterListContext } from "../../context/FiltersListContext";
import { ProjectContext } from "../../context/ProjectContext";
import { areFiltersApplied } from "../../utils/helper/AreFiltersApplied";
import { useHasAccessToFeature } from "../../utils/helper/HasAccessToFeature";
import CustomPagination from "../Common/CustomPagination";
import TableHeader from "../Common/TableHeader";
import TableIntro from "../Common/TableIntro";
import usePinnedData from "../CustomHooks/usePinnedData";
import ProjectModal from "./ProjectModal";
import ProjectsTableBody from "./ProjectsTableBody";
import { Authorization_header } from "../../utils/helper/Constant";
import SpocModalForm from "../Common/SpocModalForm";
import ProjectsAddModal from "./ProjectsAddModal";
import SheetsListing from "../Common/SheetsListing";
import { SheetsContext } from "../../context/SheetsContext";

const tableData = {
  columns: [
    "Project Name",
    "Project ID",
    "Account",
    "Fiscal Year",
    "SPOC Name",
    "SPOC Email",
    "Total Expense",
    "R&D Expense",
    "R&D Potential (%)"
  ],
};

const styles = {
  uploadButtonStyle: {
    borderRadius: "20px",
    textTransform: "capitalize",
    backgroundColor: "#00A398",
    "&:hover": { backgroundColor: "#00A398" },
  },
  buttonStyle: {
    mr: 1,
    borderRadius: "20px",
    textTransform: "capitalize",
    backgroundColor: "#9F9F9F",
    "&:hover": { backgroundColor: "#9F9F9F" },
  }, iconStyle: { fontSize: "20px", color: "#9F9F9F" },
  newCompanyButtonStyle: {
    textTransform: "capitalize",
    borderRadius: "20px",
    backgroundColor: "#00A398",
    mr: 2,
    mb: "-5%",
    "&:hover": {
      backgroundColor: "#00A398",
    },
  },
}


function ProjectsTableStack({ latestUpdateTime, getSelectedTab, projectsSheets }) {
  const {
    projects,
    fetchProjects,
    projectFilterState,
    setCurrentState,
    currentState,
    loading,

  } = useContext(ProjectContext);
  const [currentPageProjects, setCurrentPageProjects] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(20);
  const [modalOpen, setModalOpen] = useState(false);
  const [search, setSearch] = useState("");
  const [filteredRows, setFilteredRows] = useState([]);
  const { pinnedObject } = usePinnedData();
  const { fetchUserDetails } = useContext(FilterListContext);
  const [updateIds, setUpdateIds] = useState([]);
  const [updatePurpose, setUpdatePurpose] = useState("updates");
  const [handleConfirmationModalOpen, setHandleConfirmationModalOpen] = useState(false);
  const [updateData, setUpdateData] = useState([]);
  const [showSendUpdates, setShowSendupdates] = useState(false);
  const [spocProjects, setSpocProjects] = useState([]);
  const [selectedProjectIds, setSelectedProjectIds] = useState([]);
  const { fetchProjectsSheets } = useContext(SheetsContext);
  const [spockName, setSpockName] = useState("");
  const [spockEmail, setSpockEmail] = useState("");
  const [sheetsToBeShown, setSheetsToBeShown] = useState([]);
  const projectNavs = [
    { name: "All Projects", isAuth: true },
    { name: "Uploaded Sheets", isAuth: true }
  ];
  const [selectedTab, setSelectedTab] = useState("All Projects");
  const [pinStates, setPinStates] = useState({
    "All Projects": false,
    "Recently Viewed": false,
  });
  const [showAddProjectsModal, setShowAddProjectsModal] = useState(false);
  // const [company, setCompany] = useState("");

  useEffect(() => {
    if (projectsSheets && projectsSheets.length > 0) {
      let i = 0;
      let newSheets = [];
      for (; i < itemsPerPage; i++) {
        let obj = {};
        for (let key in projectsSheets[0]) {
          obj[key] = "";
        }
        newSheets.push(obj);
      }
      setSheetsToBeShown(newSheets);
    }
  }, [itemsPerPage]);

  const handleUpdateSpockData = (spName, spMail) => {
    setSpockName(spName);
    setSpockEmail(spMail);
  }

  const handleSelectedTab = (name) => {
    setSelectedTab(name);
  }

  const handleSendMail = async ({ updateIds }) => {
    toast.loading(`${updatePurpose} sending...`);
    const querryData = { updateIds: [...updateIds], purpose: "PROJECT", spocName: spockName, spocEmail: spockEmail };
    if (updatePurpose === "updates") {
      querryData.sendupdate = true;
    }
    try {
      const res = await axios.post(`${BaseURL}/api/v1/contacts/${localStorage.getItem(
        "userid"
      )}/update-spoc`, querryData, Authorization_header());
      toast.dismiss();
      toast.success(`${updatePurpose} sent successfully...`);

    } catch (error) {
      toast.dismiss();
      toast.error(error?.response?.data?.message || `Failed to send ${updatePurpose}. Server error`);
      console.error(error);
    }
  }

  let appliedFilters = {};

  if (projectFilterState.company) {
    appliedFilters.Clients = projectFilterState.company;
  }

  if (projectFilterState.project) {
    appliedFilters.Projects = projectFilterState.project;
  }

  if (projectFilterState.totalExpense && projectFilterState.totalExpense[0] > 0) {
    appliedFilters.MinTotalExpense = projectFilterState.totalExpense[0];
  }

  if (projectFilterState.totalExpense && projectFilterState.totalExpense[1] < 2000000) {
    appliedFilters.MaxTotalExpense = projectFilterState.totalExpense[1];
  }

  if (projectFilterState.rndExpense && projectFilterState.rndExpense[0] > 0) {
    appliedFilters.MinRnDExpense = projectFilterState.rndExpense[0];
  }

  if (projectFilterState.rndExpense && projectFilterState.rndExpense[1] < 2000000) {
    appliedFilters.MaxRnDExpense = projectFilterState.rndExpense[1];
  }

  if (projectFilterState.rndPotential && projectFilterState.rndPotential[0] > 0) {
    appliedFilters.MinRnDPotential = projectFilterState.rndPotential[0];
  }

  if (projectFilterState.rndPotential && projectFilterState.rndPotential[1] < 100) {
    appliedFilters.MaxRnDPotential = projectFilterState.rndPotential[1];
  }

  if (projectFilterState.sortField) {
    appliedFilters.SortField = projectFilterState.sortField;
  }

  if (projectFilterState.sortOrder) {
    appliedFilters.SortOrder = projectFilterState.sortOrder;
  }


  // appliedFilters = {
  //   Clients: projectFilterState.company,
  //   Projects: projectFilterState.project,
  //   AccountingYear: projectFilterState.accYear,
  //   MinTotalExpense: projectFilterState.totalExpense[0],
  //   MaxTotalExpense: projectFilterState.totalExpense[1],
  //   MinRnDExpense: projectFilterState.rndExpense[0],
  //   MaxRnDExpense: projectFilterState.rndExpense[1],
  //   MinRnDPotential: projectFilterState.rndPotential[0],
  //   MaxRnDPotential: projectFilterState.rndPotential[1],
  // };

  const filterSpocProjects = () => {
    const sp = projects?.filter((p) => {
      return ((!p.spocName || !p.spocEmail || p.spocEmail === "" || p.spocName === ""))
    })
    setSpocProjects(sp);
  }

  useEffect(() => {
    filterSpocProjects();
  }, [projects])

  useEffect(() => {
    const updatedPinStates = {
      "All Projects": pinnedObject.PROJ === "ALL",
      "Recently Viewed": pinnedObject.PROJ === "RV",
    };
    setPinStates(updatedPinStates);
  }, [pinnedObject.PROJ]);

  useEffect(() => {
    const shouldFetchWithFiltersProjects =
      projectFilterState.companyId?.length > 0 ||
      projectFilterState.portfolioId?.length > 0 ||
      projectFilterState.accountingYear?.length > 0 ||
      projectFilterState.totalExpense?.length > 0 ||
      projectFilterState.rndExpense?.length > 0 ||
      projectFilterState.rndPotential?.length > 0;

    if (shouldFetchWithFiltersProjects) {
      let options = {
        ...(projectFilterState.companyId?.length > 0 && {
          companyId: projectFilterState.companyId,
        }),
        ...(projectFilterState.accountingYear?.length > 0 && {
          accountingYear: projectFilterState.accountingYear,
        }),
        ...(projectFilterState.totalExpense && {
          minTotalExpense: projectFilterState.totalExpense[0],
        }),
        ...(projectFilterState.totalExpense && {
          maxTotalExpense: projectFilterState.totalExpense[1],
        }),
        ...(projectFilterState.rndExpense && {
          minRnDExpense: projectFilterState.rndExpense[0],
        }),
        ...(projectFilterState.rndExpense && {
          maxRnDExpense: projectFilterState.rndExpense[1],
        }),
        ...(projectFilterState.rndPotential && {
          minRnDPotential: projectFilterState.rndPotential[0],
        }),
        ...(projectFilterState.rndPotential && {
          maxRnDPotential: projectFilterState.rndPotential[1],
        }),
      };
      fetchProjects(options);
    } else {
      fetchProjects();
    }
  }, [currentState]);

  const applyFiltersAndFetch = (filters) => {
    if (areFiltersApplied(appliedFilters)) {
      fetchProjects(filters);
    } else {
      toast.error("Please select at least one filter.");
    }
  };

  const totalPagesProjects = Math.ceil(filteredRows?.length / itemsPerPage);
  const totalPagesSheets = Math.ceil(projectsSheets?.length / itemsPerPage);

  const handleChangePage = (newPage) => {
    setCurrentPageProjects(newPage);

  };

  const handleChangeItemsPerPage = (items) => {
    setItemsPerPage(items);
    // setMinRows(items)
    setCurrentPageProjects(1); // Reset to first page when items per page changes
  };

  const currentData = filteredRows?.slice(
    (currentPageProjects - 1) * itemsPerPage,
    currentPageProjects * itemsPerPage
  );

  while (currentData?.length < itemsPerPage) {
    currentData?.push({}); // Push an empty object or a placeholder object
  }

  const handleUploadClick = () => {
    setModalOpen(true);
  };


  const handleProjectsUploadClick = () => {
    setShowAddProjectsModal(true);
  }

  const handleProjectsUploadClose = () => {
    setShowAddProjectsModal(false);
  }

  const handleSendUpdatesClick = (val) => {
    setUpdateIds([]);
    if (updatePurpose === 'updates') {
      setShowSendupdates(true);
    }
    setUpdatePurpose("updates");
  }

  const handleShowSendUpdates = () => {
    setShowSendupdates(!showSendUpdates);
  }

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const handleSearch = (input) => {
    setSearch(input);

  };

  useEffect(() => {
    if (projects) {
      const filteredData = projects?.filter(
        (task) =>
          task?.projectName?.toString()?.toLowerCase()?.includes(search?.toLowerCase()) ||
          task?.projectId?.toString()?.toLowerCase()?.includes(search?.toLowerCase()) ||
          task?.projectCode?.toString()?.toLowerCase()?.includes(search?.toLowerCase()) ||
          task.firstName?.toString()?.toLowerCase().includes(search.toLowerCase()) ||
          task.lastName?.toString()?.toLowerCase().includes(search.toLowerCase()) ||
          task.middleName?.toString()?.toLowerCase().includes(search.toLowerCase()) ||
          task.companyName?.toString()?.toLowerCase().includes(search.toLowerCase()) ||
          task.spocEmail?.toString()?.toLowerCase().includes(search.toLowerCase()) ||
          task.spocName?.toString()?.toLowerCase().includes(search.toLowerCase()) ||
          task?.description?.toString()?.toLowerCase()?.includes(search?.toLowerCase()) ||
          task?.companyId?.toString()?.toLowerCase()?.includes(search?.toLowerCase()) ||
          task?.accountingYear?.toString()?.toLowerCase()?.includes(search?.toLowerCase()) ||
          task?.totalExpense?.toString()?.toLowerCase()?.includes(search?.toLowerCase()) ||
          task?.RnDExpenseCumulative?.toString()?.toLowerCase()?.includes(search?.toLowerCase()) ||
          task?.rndpotential?.toString?.toLowerCase()?.includes(search?.toLocaleLowerCase()));

      setFilteredRows(filteredData);
      setCurrentPageProjects(1);

    } else {
      setFilteredRows([]);
    }
  }, [projects, search]);

  const handleSelectedHeaderItem = (item) => {
    setCurrentState(item);
  };

  useEffect(() => {
    setCurrentState(
      pinnedObject?.PROJ === "RV" ? "Recently Viewed" : "All Projects"
    );
  }, [localStorage?.getItem("keys")]);

  const togglePinState = (selectedHeading) => {
    setPinStates((prevStates) => {
      const resetStates = Object.keys(prevStates).reduce((acc, key) => {
        acc[key] = false;
        return acc;
      }, {});

      const newState = {
        ...resetStates,
        [selectedHeading]: !prevStates[selectedHeading],
      };

      const allFalse =
        !newState["All Projects"] && !newState["Recently Viewed"];
      if (allFalse) {
        newState["All Projects"] = true;
      }

      return newState;
    });
  };

  const updatePinState = async (newState) => {
    const newPinnedObject = {
      ...pinnedObject,
      PROJ: newState,
    };

    const pinString = Object.entries(newPinnedObject)
      .map(([key, value]) => `${key}:${value}`)
      .join("|");

    const config = {
      method: "put",
      url: `${BaseURL}/api/v1/users/${localStorage.getItem(
        "userid"
      )}/edit-user`,
      headers: { "Content-Type": "application/json" },
      data: JSON.stringify({ pin: pinString }),
    };

    try {
      const response = await axios.request(config);

      fetchUserDetails();
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const newState = Object.keys(pinStates).find(
      (key) => pinStates[key] === true
    );

    if (newState) {
      const newStateValue = newState === "All Projects" ? "ALL" : "RV";

      updatePinState(newStateValue)
        .then(() => {
        })
        .catch((error) => {
          console.error("Failed to update pin state:", error);
        });
    }
  }, [pinStates]);

  useEffect(() => {
    getSelectedTab(selectedTab);
    setCurrentPageProjects(1)
  }, [selectedTab])

  const handleUploadProject = async (values) => {
    const apiUrl = `${BaseURL}/api/v1/projects/${localStorage.getItem("userid")}/${values.companyId}/projects-upload`;

    const data = {
      companyId: values.companyId,
      projects: values.file,
    };
    toast.loading("Uploading projects sheet....");
    try {
      const tokens = localStorage.getItem('tokens');
      const token_obj = JSON.parse(tokens);
      const response = await axios.post(apiUrl, data, {
        headers: {
          "Content-Type": "multipart/form-data",
          'Authorization': `Bearer ${token_obj?.accessToken}`
        }
      });
      handleProjectsUploadClose();
      fetchProjectsSheets();
      toast.dismiss();
      toast.success(response?.data?.message || "Projects uploaded successfully");
    } catch (error) {
      console.error(error);
      toast.dismiss();
      toast.error(error?.response?.data?.message || "Failed to upload projects sheet.");
    }
  };
  return (
    <>
      <Box>
        <SpocModalForm open={showSendUpdates} tableColumn={tableData} handleClose={handleShowSendUpdates} handleSendMail={handleSendMail} handleSurveysMailOpen={handleShowSendUpdates} handleConfirmationModalOpen={handleConfirmationModalOpen} updateData={updateData} updatePurpose={updatePurpose} projects={projects} handleUpdateSpockData={handleUpdateSpockData} fetchProjects={fetchProjects} />
      </Box >
      <TableIntro
        heading={
          pinnedObject?.PROJ === "RV" ? "Recently Viewed" : "All Projects"
        }
        btnName={"New Project"}
        btnName2={"Update SPOC"}
        btnName3={"Upload"}
        page={"project"}
        data={projects}
        totalItems={filteredRows?.length || 0}
        currentPage={currentPageProjects}
        itemsPerPage={itemsPerPage}
        onUploadClick={handleUploadClick}
        onUploadClick2={handleSendUpdatesClick}
        onUploadClick3={handleProjectsUploadClick}
        onSearch={handleSearch}
        items={["All Projects", "Recently Viewed"]}
        latestUpdateTime={latestUpdateTime}
        onApplyFilters={applyFiltersAndFetch}
        appliedFilters={appliedFilters}
        createPermission={useHasAccessToFeature("F013", "P000000007")}
        searchPermission={useHasAccessToFeature("F013", "P000000009")}
        onSelectedItem={handleSelectedHeaderItem}
        isPinnedState={pinStates[currentState]}
        onPinClicked={() => togglePinState(currentState)}
        projectNavs={projectNavs}
        handleSelectedTab={handleSelectedTab}
        selectedTab={selectedTab}
        totalSheetsNumber={projectsSheets?.length}
      />
      <ProjectsAddModal
        open={showAddProjectsModal}
        handleClose={handleProjectsUploadClose}
        handleSubmit={handleUploadProject}
        type={"upload"}
      />
      <ProjectModal
        open={modalOpen}
        handleClose={handleModalClose}
        fetchProjectData={fetchProjects}
      />
      {/** Add the projects upload modal Open here **/}
      {selectedTab === "All Projects" && <TableContainer
        sx={{
          maxHeight: "82vh",
          overflowY: "auto",
        }}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHeader tableData={tableData} />
          {!loading && <ProjectsTableBody
            data={currentData}
            currentPage={currentPageProjects}
            itemsPerPage={itemsPerPage}
          />
          }
        </Table>
        {loading && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "50px",
              minHeight: "380px",
            }}
          >
            <CircularProgress sx={{ color: "#00A398" }} />
          </div>
        )}
        {currentData?.length === 0 && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "50px",
              minHeight: "380px",
            }}
          >
            No projects found.
          </div>
        )}
      </TableContainer>}
      {selectedTab === "Uploaded Sheets" && <SheetsListing page={"projects"} projectsSheets={projectsSheets} itemsPerPage={itemsPerPage} />}
      <CustomPagination
        currentPage={currentPageProjects}
        totalPages={selectedTab === "All Projects" ? totalPagesProjects : totalPagesSheets}
        changePage={handleChangePage}
        changeItemsPerPage={handleChangeItemsPerPage}
        minRows={20}
      />
      <Toaster />
    </>
  );
}

export default ProjectsTableStack;