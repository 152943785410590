import axios from "axios";
import React, { createContext, useState, useEffect } from "react";
import { BaseURL } from "../constants/Baseurl";
import usePinnedData from "../components/CustomHooks/usePinnedData";
import { Authorization_header } from "../utils/helper/Constant";
import { useAuthContext } from "./AuthProvider";
import toast from "react-hot-toast";

export const ProjectContext = createContext();

export const ProjectProvider = ({ children }) => {
  const { pinnedObject } = usePinnedData();
  const [projects, setProjects] = useState([]);
  const [projectFilterState, setProjectFilterState] = useState({
    companyId: [],
    portfolioId: [],
    accountingYear: [],
    accYear: "",
    company: "",
    project: "",
    totalExpense: [0, 2000000],
    rndExpense: [0, 2000000],
    rndPotential: [0, 100],
    sortOrder: "",
    sortField: ""
  });
  const [clearProjectFilterTrigger, setClearProjectFilterTrigger] =
    useState(false);
  const [isProjectFilterApplied, setIsProjectFilterApplied] = useState(false);
  const [currentState, setCurrentState] = useState(
    pinnedObject?.PROJ === "RV" ? "Recently Viewed" : "All Projects"
  );
  const [timesheetProject, setTimesheetProject] = useState([]);
  const [loading, setLoading] = useState(false);
  const { logout } = useAuthContext();

  const triggerProjectClearFilters = () => {
    // Reset the filter state to its initial values
    setProjectFilterState({
      companyId: [],
      portfolioId: [],
      accountingYear: [],
      accYear: "",
      company: "",
      project: "",
      totalExpense: [0, 2000000],
      rndExpense: [0, 2000000],
      rndPotential: [0, 100],
      sortOrder: "",
      sortField: ""
    });

    // Toggle the clearProjectFilterTrigger to notify the useEffect
    setClearProjectFilterTrigger((prev) => !prev);
  };
  useEffect(() => {
    // Fetch all projects when clearProjectFilterTrigger changes
    if (clearProjectFilterTrigger) {
      fetchProjects();
    }
  }, [clearProjectFilterTrigger]);
  function getAccessToken() {
    const tokens = localStorage.getItem('tokens');
    const token_obj = JSON.parse(tokens);
    return token_obj?.accessToken || '';
  }

  const ProjectSort = async ({ sortField, sortOrder }) => {
    const url = `${BaseURL}/api/v1/projects/${localStorage.getItem("userid")}/a0ds/get-projects`;
    try {
      toast.loading("Fetching projects data");
      const config = {
        headers: {
          Authorization: `Bearer ${getAccessToken()}`,
        },
        params: {
          sortField: sortField,
          sortOrder: sortOrder,
        },
      };
      const response = await axios.get(url, config)
      toast.dismiss();
      setProjects(response?.data?.data);
      toast.success(response?.data?.message || "Succesfully fetched data");
    } catch (error) {
      toast.dismiss();
      toast.error("Error in fetching client data");
      console.error("Error in fetching client data : ", error);
    }
  }

  const fetchProjects = async (options = {}) => {
    setLoading(true);
    const queryParams = new URLSearchParams();
    if (options.companyId || projectFilterState.companyId.length > 0) {
      queryParams.append("companyIds", JSON.stringify(options.companyId || projectFilterState.companyId));
    }
    if (options.timesheetId)
      queryParams.append("timesheetId", JSON.stringify(options.timesheetId));
    if (options.accountingYear || projectFilterState.accountingYear.length > 0)
      queryParams.append(
        "fiscalYears",
        JSON.stringify(options.accountingYear || projectFilterState.accountingYear)
      );
    // Validate and append min/max values only if the user has interacted with them
    if (options.minTotalExpense != null && options.minTotalExpense > 0) {
      queryParams.append("minTotalExpense", options.minTotalExpense);
    }

    if (options.maxTotalExpense != null && options.maxTotalExpense < 2000000) {
      queryParams.append("maxTotalExpense", options.maxTotalExpense);
    }

    if (options.minRnDExpense != null && options.minRnDExpense > 0) {
      queryParams.append("minRnDExpense", options.minRnDExpense);
    }

    if (options.maxRnDExpense != null && options.maxRnDExpense < 2000000) {
      queryParams.append("maxRnDExpense", options.maxRnDExpense);
    }

    if (options.minRnDPotential != null && options.minRnDPotential > 0) {
      queryParams.append("minRnDPotential", options.minRnDPotential);
    }

    if (options.maxRnDPotential != null && options.maxRnDPotential < 100) {
      queryParams.append("maxRnDPotential", options.maxRnDPotential);
    }
    if (options?.sortField != null && options?.sortField) {
      queryParams.append("sortField", options.sortField)
    }
    if (options?.sortOrder != null && options?.sortOrder) {
      queryParams.append("sortOrder", options.sortOrder)
    }

    const queryString = queryParams.toString();
    const url = `${BaseURL}/api/v1/projects/${localStorage.getItem(
      "userid"
    )}/a0ds/get-projects${queryString ? `?${queryString}` : ""}`;
    setIsProjectFilterApplied(queryString);
    try {
      const response = await axios.get(url, Authorization_header());
      setProjects(response?.data?.data);
      if (options?.timesheetId) {
        setTimesheetProject(response?.data?.data)
      }
      setLoading(false)
    } catch (error) {
      setLoading(false);
      if (error?.response?.data?.logout === true || error?.response?.data?.message === "session timed out") {
        toast.error("Session expired, you need to login again");
        logout();
      }
      console.error("Failed to fetch Projects:", error);
    }
  };

  return (
    <ProjectContext.Provider
      value={{
        projects,
        timesheetProject,
        fetchProjects,
        ProjectSort,
        projectFilterState,
        setProjectFilterState,
        triggerProjectClearFilters,
        clearProjectFilterTrigger,
        isProjectFilterApplied,
        setIsProjectFilterApplied,
        setCurrentState,
        currentState,
        loading
      }}
    >
      {children}
    </ProjectContext.Provider>
  );
};
