import React, { useEffect, useState } from 'react';
import { BaseURL } from '../constants/Baseurl';
import axios from 'axios';
import FormatDatetime from '../utils/helper/FormatDatetime';
import { Authorization_header } from '../utils/helper/Constant';
import { useAuthContext } from './AuthProvider';
import toast from 'react-hot-toast';
import usePinnedData from '../components/CustomHooks/usePinnedData';

export const CaseContext = React.createContext();

export const CaseContextProvider = ({ children }) => {
  const [caseData, setCaseData] = useState([]);
  const [cases, setCase] = useState([]);
  const [selectedCase, setSelectedCase] = useState(null);
  const [detailedCase, setDetailedCase] = useState(null);
  const [reqCaseId, setReqCaseId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [currency, setCurrency] = useState(null);
  const [clearCaseFilterTrigger, setClearCaseFilterTrigger] = useState(false);
  const [isCaseFilterApplied, setIsCaseFilterApplied] = useState(false);
  const { logout } = useAuthContext();
  const { pinnedObject } = usePinnedData();
  const [currentState, setCurrentState] = useState(
    pinnedObject?.CASES === 'RV' ? 'Recently Viewed' : 'All Cases'
  );
  const [caseFilterState, setCaseFilterState] = useState({
    companyId: [],
    companyName: '',
    sortField: '',
    sortOrder: '',
    countryName: '',
    caseOwnerName: '',
  });

  const triggerCaseClearFilters = () => {
    setCaseFilterState({
      companyId: [],
      companyName: '',
      sortField: '',
      sortOrder: '',
      countryName: '',
      caseOwnerName: '',
    });
    setClearCaseFilterTrigger((prev) => !prev);
  };

  const getCaseById = async (id) => {
    try {
      const response = await axios.get(
        `${BaseURL}/api/v1/case/${localStorage.getItem('userid')}/${id}/casedetails`,
        Authorization_header()
      );
      const singleCase = response?.data?.data?.casedetails;
      setDetailedCase(singleCase);
    } catch (error) {
      if (error?.response?.data?.logout) {
        logout();
      }
      console.error('Error fetching case by ID:', error);
    }
  };

  const getAccessToken = () => {
    const tokens = localStorage.getItem('tokens');
    const tokenObj = JSON.parse(tokens);
    return tokenObj?.accessToken || '';
  };

  const caseSort = async ({ sortField, sortOrder }) => {
    const url = `${BaseURL}/api/v1/case/${localStorage.getItem('userid')}/cases`;
    try {
      toast.loading('Fetching case data');
      const config = {
        headers: {
          Authorization: `Bearer ${getAccessToken()}`,
        },
        params: {
          sortField,
          sortOrder,
        },
      };
      const response = await axios.get(url, config);
      const caseList = response?.data?.data?.caseDetails?.map((elemnt) => ({
        caseCode: elemnt.caseCode,
        caseId: elemnt.caseId,
        status: elemnt.status,
        caseType: elemnt.caseType,
        companyId: elemnt.companyId,
        companyName: elemnt.companyName,
        countryName: elemnt.countryName,
        contactId: elemnt.primaryContactId,
        contactName: elemnt.primaryContactName,
        caseRoleName: elemnt.caseRoleName,
        caseOwnerName: elemnt?.caseOwnerName,
        email: elemnt.primaryContactEmail,
        caseTypeDescription: elemnt.caseTypeDescription,
        createdOn: FormatDatetime(elemnt?.createdOn).split('T')[0].split(' ')[0],
      }));
      setCaseData(caseList);
      toast.dismiss();
      toast.success(response?.data?.message || 'Successfully fetched data');
    } catch (error) {
      toast.dismiss();
      toast.error('Error in fetching Timesheet data');
      console.error('Error in fetching client data:', error);
    }
  };

  const getAllCases = async (options = {}) => {
    setLoading(true);
    const queryParams = new URLSearchParams();
    if (options.sortField) queryParams.append('sortField', options?.sortField);
    if (options.sortOrder) queryParams.append('sortOrder', options?.sortOrder);
    const queryString = queryParams.toString();
    const url = `${BaseURL}/api/v1/case/${localStorage.getItem('userid')}/cases${queryString ? `?${queryString}` : ''}`;
    setIsCaseFilterApplied(!!queryString);

    try {
      const response = await axios.get(url, Authorization_header());
      const caseList = response?.data?.data?.caseDetails?.map((elemnt) => ({
        caseCode: elemnt.caseCode,
        caseId: elemnt.caseId,
        status: elemnt.status,
        caseType: elemnt.caseType,
        companyId: elemnt.companyId,
        companyName: elemnt.companyName,
        countryName: elemnt.countryName,
        contactId: elemnt.primaryContactId,
        contactName: elemnt.primaryContactName,
        caseRoleName: elemnt.caseRoleName,
        caseOwnerName: elemnt?.caseOwnerName,
        email: elemnt.primaryContactEmail,
        caseTypeDescription: elemnt.caseTypeDescription,
        createdOn: FormatDatetime(elemnt?.createdOn).split('T')[0].split(' ')[0],
      }));
      setCaseData(caseList);
      setLoading(false);
      return caseList;
    } catch (error) {
      if (error?.response?.data?.logout || error?.response?.data?.message === 'session timed out') {
        toast.error('Session expired, you need to login again');
        logout();
      }
      setLoading(false);
      console.error('Error fetching all cases:', error);
    }
  };

  const getCaseOnLanding = async (options = {}) => {

    const queryParams = new URLSearchParams(window.location.search);
    if (options.client && options.client[0]) {
      queryParams.append('companyIds', JSON.stringify(options.client));
      if (currentState === 'Recently Viewed') queryParams.append('recentlyViewed', true);
    }
    if (options.countryName) queryParams.append('Location', options.countryName);
    if (options.caseOwnerName) queryParams.append('caseOwnerName', options.caseOwnerName);

    const value = queryParams.get('caseId');
    setReqCaseId(value);

    const queryString = queryParams.toString();
    const url = `${BaseURL}/api/v1/case/${localStorage.getItem('userid')}/cases${queryString ? `?${queryString}` : ''}`;

    setIsCaseFilterApplied(Boolean(queryString));

    try {
      const response = await axios.get(url, Authorization_header());
      setCase(response?.data?.data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching case on landing:', error);
      if (error?.response?.data?.logout || error?.response?.data?.message === 'session timed out') {
        toast.error('Session expired, you need to login again');
        logout();
      }
      setLoading(false);
    }
  };

  const handleSelectedCase = (c) => {
    setSelectedCase(c);
  };

  return (
    <CaseContext.Provider
      value={{
        getAllCases,
        caseFilterState,
        setCaseFilterState,
        isCaseFilterApplied,
        caseData,
        selectedCase,
        handleSelectedCase,
        getCaseById,
        detailedCase,
        currency,
        loading,
        getCaseOnLanding,
        setCurrentState,
        caseSort,
        triggerCaseClearFilters,
        setIsCaseFilterApplied,
        clearCaseFilterTrigger,
      }}
    >
      {children}
    </CaseContext.Provider>
  );
};
