import {
    Box,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { BaseURL } from "../../constants/Baseurl";
import { ProjectContext } from "../../context/ProjectContext";
import LocationFilter from "../FilterComponents/LocationFilter";
import CompanySelector from "../FilterComponents/CompanySelector";
import ActionButton from "../FilterComponents/ActionButton";
import { ClientContext } from "../../context/ClientContext";
import SliderInput from "../FilterComponents/SliderInput";
import { Authorization_header } from "../../utils/helper/Constant";
// import PortfolioSelector from "../FilterComponents/PortfolioSelector";

function AccountFilters({ clientData, getCompanySortParams, companySort }) {
    const {
        clientFilters,
        setClientFilters,
        clearClientFilterTrigger,
    } = useContext(ClientContext);
    const [company, setCompany] = useState(clientFilters.company);
    const [billingCountry, setBillingCountry] = useState(clientFilters.billingCountry);
    const [billingCountryList, setBillingCountryList] = useState([]);
    const [sortField, setSortField] = useState("");
    const [sortOrder, setSortOrder] = useState("");


    useEffect(() => {
        getCompanySortParams({ sortField: sortField, sortOrder: sortOrder })
    }, [sortField, sortOrder])

    const handleFilterChange = (field) => (event, newValue) => {
        const value = newValue ?? event.target.value;
        if (field === "sortField") {
            setSortField(event.target.value);
        } else if (field === "sortOrder") {
            setSortOrder(event.target.value);
        }
        if (field === "sortField" || field === "sortOrder") {
            setClientFilters({
                ...clientFilters,
                [field]: value?.props?.value,
            });
        } else {
            setClientFilters({
                ...clientFilters,
                [field]: value
            })
        }
    };


    useEffect(() => {
        setClientFilters({
            ...clientFilters,
            companyId: [
                clientData?.find((client) => client?.companyName === company)
                    ?.companyId,
            ],
            company: company,
        });
    }, [company]);

    useEffect(() => {
        setClientFilters({
            ...clientFilters,
            billingCountry,
            countryId: [
                billingCountryList?.find((proj) => proj?.countryName === billingCountry)?.countryId,
            ],
            company: company,
        });
    }, [company, billingCountry]);

    useEffect(() => {
        if (sortField) {
            setClientFilters({
                ...clientFilters,
                sortField
            })
        } else if (sortOrder) {
            setClientFilters({
                ...clientFilters,
                sortOrder
            })
        }
    }, [sortField, sortOrder])

    useEffect(() => {
        setClientFilters({
            ...clientFilters,
            projectsCount: [1, 2000000],
            billingCountry: "",
            totalProjectCost: [1, 2000000],
            totalRnDCost: [1, 2000000],
        });
    }, []);

    const fetchBillingCountryList = async () => {
        let url;
        if (clientFilters.companyId) {
            url = `${BaseURL}/api/v1/company/${localStorage.getItem("userid")}/get-companys-billing-country?companyIds=${JSON.stringify(
                clientFilters.companyId
            )}`;
        } else {
            url = `${BaseURL}/api/v1/company/${localStorage.getItem("userid")}/get-companys-billing-country`;
        }

        try {
            const response = await axios.get(url, Authorization_header());
            setBillingCountryList(response?.data?.data);

        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        fetchBillingCountryList();
    }, [clientFilters.companyId]);

    useEffect(() => {
        if (clearClientFilterTrigger) {
            setBillingCountry("");
            setClientFilters({
                ...clientFilters,
                projectsCount: [1, 2000000],
                billingCountry: "",
                totalProjectCost: [1, 2000000],
                totalRnDCost: [1, 2000000],
            });
        }
    }, [clearClientFilterTrigger]);


    return (
        <Box>
            <LocationFilter
                billingCountry={billingCountry}
                billingCountryList={billingCountryList}
                setBillingCountry={setBillingCountry}
            />
            <SliderInput
                minWidth={220}
                label="No. Of Projects"
                // value={[0,2000]}
                value={clientFilters?.projectsCount}
                onChange={handleFilterChange("projectsCount")}
                initialMin={0}
                initialMax={2000000}
            />
            <SliderInput
                initialMin={0}
                initialMax={2000000}
                value={clientFilters?.totalProjectCost}
                onChange={handleFilterChange("totalProjectCost")}
                label="Total Expense"
                minWidth={220}
            />
            <SliderInput
                initialMin={0}
                initialMax={2000000}
                minWidth={220}
                label="R&D Expense"
                value={clientFilters?.totalRnDCost}
                onChange={handleFilterChange("totalRnDCost")}
            />


            {/* Expandable Sorts Section */}
            <Accordion sx={{ maxWidth: '100%', overflowX: 'hidden' }}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography>Sorts</Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ overflowX: 'hidden' }}>
                    {/* Sort Field Selector */}
                    <FormControl
                        fullWidth
                        margin="normal"
                    // variant="outlined" // To match existing MUI styling of similar dropdowns
                    >
                        <InputLabel>Sort Field</InputLabel>
                        <Select
                            sx={{ width: '100%' }}
                            label="Sort Field"
                            value={sortField}
                            onChange={
                                handleFilterChange("sortField")
                            }
                        >
                            {/* <MenuItem value="fiscalYear">Fiscal Year</MenuItem>
              <MenuItem value="accountingYear">Accounting Year</MenuItem> */}
                            <MenuItem value="totalExpense">Total Expense</MenuItem>
                            <MenuItem value="rndExpense">R&D Expense</MenuItem>
                            <MenuItem value="rndPotential">R&D Potential</MenuItem>
                            <MenuItem value="totalProjects">Total Projects</MenuItem>
                        </Select>
                    </FormControl>

                    {/* Sort Order Selector */}
                    <FormControl
                        fullWidth
                        margin="normal"
                        variant="outlined" // To match existing MUI styling of similar dropdowns
                    >
                        <InputLabel>Sort Order</InputLabel>
                        <Select
                            label="Sort Order"
                            value={sortOrder}
                            onChange={handleFilterChange("sortOrder")}
                            sx={{ width: '100%' }}
                        >
                            <MenuItem value="ascending">Ascending</MenuItem>
                            <MenuItem value="descending">Descending</MenuItem>
                        </Select>
                    </FormControl>
                    {/* <Box sx={{ display: "flex", justifyContent: "flex-end", padding: "0%" }}>
                        <ActionButton
                            label="Apply"
                            color="#00A398"
                            onClick={companySort} />
                    </Box> */}
                </AccordionDetails>

            </Accordion>
        </Box>
    );
}

export default AccountFilters;

