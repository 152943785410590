import React, { useContext, useEffect, useRef, useState } from "react";
import { Box, Button, CircularProgress, InputBase, InputAdornment, InputLabel, Table, TableContainer, TablePagination } from "@mui/material";
import NewSurveysInteractionModal from "../../Common/NewSurveysInteractionModal";
import CaseSurveyListing from "./CaseSurveyListing";
import CaseAddSurveysModal from "../CaseAddSurveysModal";
import { BaseURL } from "../../../constants/Baseurl";
import axios from "axios";
import MiniTableHeader from "../../Common/MiniTableHeader";
import { CaseContext } from "../../../context/CaseContext";
import toast, { Toaster } from "react-hot-toast";
import SurveyInfoboxTable from "./SurveyInfoboxTable";
import SearchIcon from "@mui/icons-material/Search";
import { Authorization_header } from "../../../utils/helper/Constant";
import SpocIncludeProjectsModal from "../SpocIncludeProjectsModal";


const styles = {
    inputBase: {
        borderRadius: "20px",
        height: "40px",
        border: "1px solid #E4E4E4",
        pl: 1,
        width: "200px",
    },
    searchIconStyle: {
        color: "#9F9F9F",
        ml: "3px",
        mr: "-3px",
        width: "20px",
        height: "20px",
    },
    label: {
        color: "#404040",
        fontSize: "14px",
        fontWeight: 600,
        flexGrow: 1,
    },
    buttonStyle: {
        mr: 1,
        borderRadius: "20px",
        textTransform: "capitalize",
        backgroundColor: "#9F9F9F",
        "&:hover": { backgroundColor: "#9F9F9F" },
    },
    uploadBoxStyle: {
        border: "1px dashed #E4E4E4",
        borderWidth: "2px",
        ml: 2,
        mr: 2,
        borderRadius: "20px",
        height: "300px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
    },
    uploadButtonStyle: {
        borderRadius: "20px",
        textTransform: "capitalize",
        backgroundColor: "#00A398",
        "&:hover": { backgroundColor: "#00A398" },
    },
    surveysMailModal: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    }
};

const tableData = {
    columns: [
        "Survey ID",
        "Project ID",
        "Project Name",
        "Status",
        "Sent Date",
        "Response Date",
        "Age(Days)",
        "Sent By",
        "Sent To",
        "External Link",
        "Action"
    ],
    rows: [
        {
            id: 1,
            projectId: "",
            timesheet: "",
            month: "",
            rndHours: "",
            hourlyRate: "",
            rndExpense: "",
        },
    ],
};

const CaseSurvrysTab = ({ modifiedBy, latestUpdateTime, handleShowSurveyDetails, handleSelectedSurveyId, getReminderStatusId, company }) => {
    const [filteredSurvey, setFilteredSurvey] = React.useState([]);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [page, setPage] = React.useState(0);
    const [open, setOpen] = React.useState(true);
    const [interactionOpen, setinteractionOpen] = React.useState(false);
    const [selectedEmail, setSelectedEmail] = React.useState("");
    const [openSurveyMailModal, setOpenSurveyMailModal] = React.useState(false);
    const [caseProjects, setCaseProjects] = useState([]);
    const { detailedCase } = useContext(CaseContext);
    const [caseSurveysList, setCaseSurveysList] = useState([]);
    const [code, setCode] = React.useState(null);
    const [loading, setLoading] = useState(false);
    const [caseSurveyDetails, setCaseSurveyDetails] = useState(null);
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [search, setSearch] = useState("");
    const [showUpdateSpocModal, setShowUpdateSpocModal] = useState(false);
    const [unsentSurveyList, setUnsentSurveyList] = useState([]);
    const [spocUpdateList, setSpocUpdateList] = useState([]);
    const UpdatePurposeRef = useRef();
    const [purpose, setPurpose] = useState("");
    const [selectedSurveyType, setSelectedSurveyType] = useState("");

    const handleSelectedSurveyType = (value) => {
        setSelectedSurveyType(value);
    }

    UpdatePurposeRef.current = "Surveys"

    useEffect(() => {
        if (Array.isArray(caseSurveysList)) {

            setFilteredSurvey(caseSurveysList?.filter((_, index) => {
                return (index >= ((0) * rowsPerPage)) && (index <= (1 * (rowsPerPage - 1)));
            }));

        }
    }, [caseSurveysList, rowsPerPage])

    const handleSurveysMailOpen = () => {
        setOpenSurveyMailModal(true);
    }



    useEffect(() => {

    }, [showUpdateSpocModal])

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        if (Array.isArray(caseSurveysList)) {
            setFilteredSurvey(caseSurveysList?.filter((_, index) => {
                return (index >= ((newPage) * rowsPerPage)) && (index <= ((newPage + 1) * rowsPerPage));
            }));
        }
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleSpocShow = () => {
        setShowUpdateSpocModal(true);
    };

    const handleSpocClose = () => {
        setShowUpdateSpocModal(false);
    }

    const fetchAddedCaseProjects = async () => {
        setCaseProjects([]);
        try {
            const response = await axios.get(
                `${BaseURL}/api/v1/case/${localStorage.getItem("userid")}/${detailedCase?.caseId
                }/projects`, Authorization_header()
            );
            setCaseProjects(response?.data?.data);
        } catch (error) {
            console.error(error);
            setCaseProjects([]);
        }
    };

    const fetchSurveyList = async () => {
        setLoading(true);
        try {
            const response = await axios.get(
                `${BaseURL}/api/v1/case/${localStorage.getItem("userid")}/${detailedCase.caseId}/surveylist`, Authorization_header()
            );

            setCaseSurveysList(response?.data?.data.data);
            setUnsentSurveyList(response?.data?.data?.unSentSurveys || []);
            const count = response?.data?.data?.counts;
            setCaseSurveyDetails({ totalSurveysSent: count?.totalSurveysSent, totalSurveysNotSent: count?.totalSurveysNotSent, totalResponsesReceived: count?.totalResponsesReceived, totalRemindersSent: count?.totalRemindersSent });
            setLoading(false);
        } catch (error) {
            console.error(error);
            setLoading(false);
            setCaseSurveysList([]);
        }
    }

    useEffect(() => {
        fetchSurveyList();
        fetchAddedCaseProjects();
    }, [detailedCase?.caseId]);

    useEffect(() => {
        setSpocUpdateList([...unsentSurveyList, ...caseSurveysList]);
    }, [unsentSurveyList, caseSurveysList])

    useEffect(() => {
    }, [spocUpdateList])

    const handleUploadClick = (val) => {
        // setSelectedEmail(val);
        setPurpose(val)
        setinteractionOpen(true);
    };

    const handleMailModalClose = (value) => {
        setinteractionOpen(false);
    };

    const handleConfirmationModalClose = () => {
        setShowConfirmationModal(false);
    }

    const handleConfirmationModalOpen = () => {
        setShowConfirmationModal(true);
    }



    const handleSendMail = async (recieversEmail, description, cc, selectedProjects, detailedSelectedSurveyIds = []) => {
        handleMailModalClose("abc");
        toast.loading("Survey sending...");
        try {
            let res;
            if (purpose === "Survey") {
                res = await axios.post(`${BaseURL}/api/v1/case/${localStorage.getItem(
                    "userid"
                )}/${detailedCase?.caseId}/sendsurvey`, { toMail: recieversEmail, mailBody: description, ccMails: cc, caseProjectIds: selectedProjects }, Authorization_header());
            } else if (purpose === "Reminder") {
                res = await axios.post(`${BaseURL}/api/v1/case/send-reminder`, { toMail: recieversEmail, mailBody: description, ccMails: cc, surveyIds: detailedSelectedSurveyIds }, Authorization_header());
            }
            toast.dismiss();
            if (purpose === "Survey") {
                toast.success("Survey sent successfully...");
            } else {
                toast.success("Reminder sent successfully...");
            }
            fetchSurveyList();
            setCode(true);
        } catch (error) {
            toast.dismiss();
            toast.error(error?.response?.data.message || "Failed to send Survey. Server error!");
            console.error(error);
            setCode(false);
        }
    }

    useEffect(() => {
        if (caseSurveysList && search) {
            const searchText = search?.trim();
            const filteredData = caseSurveysList?.filter((task) => {
                const values = Object.values(task);
                const bools = values?.filter((value) => {
                    return (value?.toLocaleString()?.trim()?.toLowerCase()?.toString()?.includes(searchText?.toLowerCase()));
                });
                return bools.length;
            })
            setFilteredSurvey(filteredData);
            // } else if (caseSurveysList && selectedSurveyType) {
            //     const filteredData = caseSurveysList?.filter((task) => {
            //         const values = Object.values(task);
            //         const bools = values?.filter((value) => {
            //             return (value?.toLocaleString()?.trim()?.toLowerCase()?.toString()?.includes(selectedSurveyType?.toLocaleLowerCase()));
            //         });
            //         return bools.length;
            //     })
            //     setFilteredSurvey(filteredData);
        }
    }, [search])

    useEffect(() => {
        if (caseSurveysList) {
            if (selectedSurveyType === "") {
                const filteredData = caseSurveysList?.map(task => task)
                setFilteredSurvey(filteredData);
            } else {
                const filteredData = caseSurveysList?.filter((task) => {
                    let values = [];
                    values.push(task.status);
                    const bools = values?.filter((value) => {
                        // return (value?.toLocaleString()?.trim()?.toLowerCase()?.toString()?.includes(selectedSurveyType?.toLocaleLowerCase()));
                        return (value?.toLocaleString()?.trim()?.toString()?.toLowerCase() === (selectedSurveyType?.toLocaleLowerCase()));
                    });
                    return bools.length;
                })
                setFilteredSurvey(filteredData);
            }
        }
    }, [selectedSurveyType])

    return (
        <>

            <Box>
                <Box
                    sx={{
                        borderTop: "1px solid #E4E4E4",
                        p: 1,
                        display: "flex",
                        flexDirection: "column",
                    }}
                >
                    <Box sx={{ px: 2, display: "flex", alignItems: "center", justifyContent: "space-between", margin: "0.3rem 0.3rem" }}>
                        <InputLabel sx={styles.label}>
                            Case Surveys
                        </InputLabel>
                        <InputBase
                            type="text"
                            value={search}
                            placeholder="Search..."
                            onChange={(e) => setSearch(e.target.value)}
                            startAdornment={
                                <InputAdornment position="start">
                                    <SearchIcon sx={styles.searchIconStyle} />
                                </InputAdornment>
                            }
                            sx={{
                                ...styles.inputBase,
                                width: "35%",
                                alignItems: "right"
                            }}
                        />
                        <Box sx={{ display: "flex", justifyContent: "flex-end",gap: "1rem", width: "40%" }}>
                            <Button
                                variant="contained"
                                sx={styles.uploadButtonStyle}
                                onClick={handleSpocShow}
                            // setSelectedEmail={selectedEmail}
                            >
                                Update SPOC
                            </Button>
                            <Button
                                variant="contained"
                                sx={styles.uploadButtonStyle}
                                onClick={() => handleUploadClick("Survey")}
                            // setSelectedEmail={selectedEmail}
                            >
                                Send Surveys
                            </Button>
                            <Button
                                variant="contained"
                                sx={styles.uploadButtonStyle}
                                onClick={() => handleUploadClick("Reminder")}
                            // setSelectedEmail={selectedEmail}
                            >
                                Send Reminder
                            </Button>
                        </Box>
                    </Box>

                    <Box>
                        <SurveyInfoboxTable caseSurveyDetails={caseSurveyDetails} handleSelectedSurveyType={handleSelectedSurveyType} />
                    </Box>
                </Box>
                <Box>
                    <TableContainer sx={{ height: 300 }}>
                        <Table stickyHeader aria-label="simple table">
                            <MiniTableHeader tableData={tableData} />

                            <CaseSurveyListing
                                filledRows={filteredSurvey}
                                handleShowSurveyDetails={handleShowSurveyDetails}
                                handleSelectedSurveyId={handleSelectedSurveyId}
                                fetchSurveyList={fetchSurveyList}
                                getReminderStatusId={getReminderStatusId}
                            />
                        </Table>
                        {loading && (
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    marginTop: "50px",
                                    minHeight: "380px",
                                }}
                            >
                                <CircularProgress sx={{ color: "#00A398" }} />
                            </div>
                        )}
                        {filteredSurvey?.length === 0 && (
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    marginTop: "50px",
                                    minHeight: "380px",
                                }}
                            >
                                No survey found.
                            </div>
                        )}
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={caseSurveysList.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Box>
            </Box>
            <SpocIncludeProjectsModal open={showUpdateSpocModal} handleClose={handleSpocClose} updatePurpose={UpdatePurposeRef.current} projects={spocUpdateList} postUpdate={fetchSurveyList} />
            <NewSurveysInteractionModal open={interactionOpen} handleClose={handleMailModalClose} recieversEmail={selectedEmail} handleSendMail={handleSendMail} handleSurveysMailOpen={handleSurveysMailOpen} caseProjects={caseProjects} company={company} handleConfirmationModalOpen={handleConfirmationModalOpen} purpose={purpose} fetchSurveyList={fetchSurveyList} caseSurveysList={caseSurveysList} />
            {/* <SurveysMailSendModal mailModalOpen={openSurveyMailModal}  handleCaseExistModal={handleCloseSurveysMailModal} caseId={detailedCase?.caseId} client={"Techm India"} code={code} recieversEmail={selectedEmail} fetchSurveyList={fetchSurveyList} handleConfirmationModalClose={handleConfirmationModalClose} /> */}
            <Toaster />
        </>
    );
};

export default CaseSurvrysTab;
