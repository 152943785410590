import { Box, Paper } from "@mui/material";
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import InfoboxHeader from "../../components/Common/InfoboxHeader";
import MainPanelHeader from "../../components/Common/MainPanelHeader";
import SearchboxBody from "../../components/Common/SearchboxBody";
import SearchboxHeader from "../../components/Common/SearchboxHeader";
import SidePanelHeader from "../../components/Common/SidePanelHeader";
import ContactInfoboxTable from "../../components/ContactDetails/ContactInfoboxTable";
import DetailsTab from "../../components/ContactDetails/DetailsTab";
import ProjectsTab from "../../components/ContactDetails/ProjectsTab";
import RndExpenseTab from "../../components/ContactDetails/RndExpenseTab";
import Salary from "../../components/ContactDetails/Salary";
import usePinnedData from "../../components/CustomHooks/usePinnedData";
import { BaseURL } from "../../constants/Baseurl";
import { ContactContext } from "../../context/ContactContext";
import { FilterListContext } from "../../context/FiltersListContext";
import { NotificationContext } from "../../context/NotificationContext";
import { areFiltersApplied } from "../../utils/helper/AreFiltersApplied";
import { useHasAccessToFeature } from "../../utils/helper/HasAccessToFeature";
import {
  getTimeDifference,
  updateTimeDiff,
  updateTimeDifference,
} from "../../utils/helper/UpdateTimeDifference";
import { Authorization_header } from "../../utils/helper/Constant";
import SalaryTab from "../../components/ContactDetails/SalaryTab";

const fieldMapping = {
  Field0: "companyName",
  Field1: "firstName",
  Field2: "phone",
  Field3: "employeeTitle",
};

function ContactDetails() {
  const arr = [
    { name: "Details", isAuth: useHasAccessToFeature("F034", "P000000003") },
    { name: "Projects", isAuth: useHasAccessToFeature("F035", "P000000003") },
    {
      name: "R&D Expense",
      isAuth: useHasAccessToFeature("F036", "P000000003"),
    },
    { name: "Wages", isAuth: useHasAccessToFeature("F037", "P000000003") },
  ];
  const { pinnedObject } = usePinnedData();
  const [selectedTab, setSelectedTab] = useState("Details");
  const [data, setData] = useState(null);
  const [contactDetails, setContactDetails] = useState(null);
  const [contactProjects, setContactProjects] = useState(null);
  const [contactSalary, setContactSalary] = useState(null);
  const [contactRnd, setContactRnd] = useState([]);
  const [search, setSearch] = useState("");
  const [filteredRows, setFilteredRows] = useState([]);
  const [latestUpdateTime, setLatestUpdateTime] = useState("Just now");
  const [latestDetailUpdateTime, setLatestDetailUpdateTime] =
    useState("Just now");
  const [latestProjectUpdateTime, setLatestProjectUpdateTime] =
    useState("Just now");
  const [latestSalaryUpdateTime, setLatestSalaryUpdateTime] =
    useState("Just now");
  const {
    contactFilterState,
    setIsContactFilterApplied,
    fetchContactData,
    contactData,
    setCurrentState,
    currentState,
  } = useContext(ContactContext);
  const [latestRnDUpdateTime, setLatestRnDUpdateTime] = useState("Just now");
  const [pinStates, setPinStates] = useState({
    "All Employees": false,
    "Recently Viewed": false,
  });
  const { fetchUserDetails } = useContext(FilterListContext);
  const [currencySymbol, setCurrencySymbol] = useState();
  const [currency, setCurrency] = useState();

  const handleSelectedItem = (selectedItemData) => {
    setData(selectedItemData);
  };

  const handleSelectedTab = (tab) => {
    setSelectedTab(tab);
  };

  const contactId = data?.contactId;

  const appliedFilters = {
    Clients: contactFilterState.company,
    Title: contactFilterState.employementType,
  };

  useEffect(() => {
    setCurrentState(
      pinnedObject?.CONT === "RV" ? "Recently Viewed" : "All Employees"
    );
  }, [localStorage?.getItem("keys")]);
  useEffect(() => {
    const codePoint = parseInt(contactDetails?.currencySymbol, 16);
    const symbol = String.fromCharCode(codePoint);
    setCurrencySymbol(symbol);
    setCurrency(contactDetails?.currency);
  }, [contactDetails]);

  useEffect(() => {
    const shouldFetchWithFiltersContact =
      contactFilterState.companyId.length > 0 ||
      contactFilterState.employementType !== "";
    if (shouldFetchWithFiltersContact) {
      let contactOptions = {
        ...(contactFilterState.companyId.length > 0 && {
          companyIds: contactFilterState.companyId,
        }),
        ...(contactFilterState.employementType !== "" && {
          employementType: [contactFilterState.employementType],
        }),
      };
      fetchContactData(contactOptions);
    } else {
      fetchContactData();
    }
  }, [currentState]);

  const applyFiltersAndFetch = (filters) => {
    if (areFiltersApplied(appliedFilters)) {
      fetchContactData(filters);
      setIsContactFilterApplied(true);
    } else {
      toast.error("Please select at least one filter.");
    }
  };
  const fetchData = async () => {
    try {
      if (contactId) {
        const response1 = await axios.get(
          `${BaseURL}/api/v1/contacts/001/1/${contactId}/get-contact-details`, Authorization_header()
        );
        setContactDetails(response1.data.data);

        const response2 = await axios.get(
          `${BaseURL}/api/v1/contacts/001/1/${contactId}/get-projects`, Authorization_header()
        );
        setContactProjects(response2.data.data);

        const response3 = await axios.get(
          `${BaseURL}/api/v1/contacts/001/1/${contactId}/get-contact-salary`, Authorization_header()
        );
        setContactSalary(response3.data.data);

        const response4 = await axios.get(
          `${BaseURL}/api/v1/contacts/user/company/${contactId}/get-rnd-expense-by-contact`, Authorization_header()
        );
        setContactRnd(response4.data.data);
      } else {
        console.error("contactId not available in data object");
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [contactId]);

  const handleSearch = (input) => {
    setSearch(input);
  };

  useEffect(() => {
    if (contactData) {
      const filteredData = contactData?.filter(
        (task) =>
          // task.projectManager.toLowerCase().includes(search.toLowerCase()) ||
          task?.employeeTitle?.toLowerCase()?.includes(search?.toLowerCase()) ||
          task?.phone?.toString()?.includes(search?.toLowerCase()) ||
          task?.companyName?.toLowerCase()?.includes(search?.toLowerCase()) ||
          `${task?.firstName} ${task?.lastName}`
            ?.toLowerCase()
            ?.includes(search?.toLowerCase())
        // task?.companyId?.toString()?.includes(search)
        // Add more conditions as needed
      );
      setFilteredRows(filteredData);
    }
  }, [contactData, search]);

  useEffect(() => {
    const timeDifference = updateTimeDifference(contactData, "createdTime");
    setLatestUpdateTime(timeDifference);
  }, [contactData]);

  useEffect(() => {
    const timeDifference = updateTimeDiff(contactDetails?.modifiedTime);
    setLatestDetailUpdateTime(timeDifference);
  }, [contactDetails]);

  useEffect(() => {
    const timeDifference1 = getTimeDifference(contactProjects, "modifiedTime");
    setLatestProjectUpdateTime(timeDifference1);
  }, [contactProjects]);

  useEffect(() => {
    const timeDifference2 = updateTimeDiff(contactSalary, "modifiedTime");
    setLatestSalaryUpdateTime(timeDifference2);
  }, [contactSalary]);

  useEffect(() => {
    const timeDifference3 = getTimeDifference(contactRnd, "modifiedTime");
    setLatestRnDUpdateTime(timeDifference3);
  }, [contactRnd]);

  const { updateAlertCriteria } = useContext(NotificationContext);

  useEffect(() => {
    const pageName = "contact";
    const relationId = contactId;

    updateAlertCriteria(pageName, relationId);

    return () => updateAlertCriteria(null, null);
  }, [contactId]);

  const handleSelectedHeaderItem = (item) => {
    setCurrentState(item);
  };

  const isDownload = useHasAccessToFeature("F033", "P000000006");

  useEffect(() => {
    const updatedPinStates = {
      "All Employees": pinnedObject.CONT === "ALL",
      "Recently Viewed": pinnedObject.CONT === "RV",
    };
    setPinStates(updatedPinStates);
  }, [pinnedObject.CONT]);

  const togglePinState = (selectedHeading) => {
    setPinStates((prevStates) => {
      const resetStates = Object.keys(prevStates).reduce((acc, key) => {
        acc[key] = false;
        return acc;
      }, {});

      const newState = {
        ...resetStates,
        [selectedHeading]: !prevStates[selectedHeading],
      };

      const allFalse =
        !newState["All Employees"] && !newState["Recently Viewed"];
      if (allFalse) {
        newState["All Employees"] = true;
      }

      return newState;
    });
  };

  const updatePinState = async (newState) => {
    const newPinnedObject = {
      ...pinnedObject,
      CONT: newState,
    };

    const pinString = Object.entries(newPinnedObject)
      .map(([key, value]) => `${key}:${value}`)
      .join("|");

    const config = {
      method: "put",
      url: `${BaseURL}/api/v1/users/${localStorage.getItem(
        "userid"
      )}/edit-user`,
      headers: { "Content-Type": "application/json" },
      data: JSON.stringify({ pin: pinString }),
    };

    try {
      const response = await axios.request(config);

      fetchUserDetails();
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const newState = Object.keys(pinStates).find(
      (key) => pinStates[key] === true
    );

    if (newState) {
      const newStateValue = newState === "All Employees" ? "ALL" : "RV";

      updatePinState(newStateValue)
        .then(() => {
        })
        .catch((error) => {
          console.error("Failed to update pin state:", error);
        });
    }
  }, [pinStates]);

  return (
    <>
      <Box
        sx={{ display: "flex", width: "98%", mx: "auto", gap: "20px", mt: 3 }}
      >
        <Paper
          sx={{
            display: "flex",
            width: "23%",
            borderRadius: "20px",
            flexDirection: "column",
            height: "100vh",
            mb: 3,
            overflowY: "hidden",
            boxShadow: "0px 3px 6px #0000001F",
          }}
        >
          <SearchboxHeader
            type={
              pinnedObject?.CONT === "RV" ? "Recently Viewed" : "All Employees"
            }
            data={filteredRows}
            onSearch={handleSearch}
            latestUpdateTime={latestUpdateTime}
            items={["All Employees", "Recently Viewed"]}
            page="contact"
            onApplyFilters={applyFiltersAndFetch}
            searchPermission={useHasAccessToFeature("F033", "P000000009")}
            onSelectedItem={handleSelectedHeaderItem}
            isPinnedState={pinStates[currentState]}
            onPinClicked={() => togglePinState(currentState)}
          />
          <Box sx={{ overflowY: "auto" }}>
            <SearchboxBody
              data={filteredRows}
              fieldMapping={fieldMapping}
              onItemSelected={handleSelectedItem}
              page={"contact"}
            />
          </Box>
        </Paper>
        <Box sx={{ width: "77%", display: "flex", flexDirection: "column" }}>
          <Paper
            sx={{
              borderRadius: "20px",
              mb: 3,
              boxShadow: "0px 3px 6px #0000001F",
            }}
          >
            <InfoboxHeader
              head={
                data?.firstName && data?.lastName
                  ? data?.firstName + " " + data?.lastName
                  : ""
              }
              page={"Employees"}
              downloadPermission={isDownload}
            />
            <ContactInfoboxTable data={data} />
          </Paper>
          <Box sx={{ display: "flex", gap: "20px", mb: 2 }}>
            <Paper
              sx={{
                borderRadius: "20px",
                width: "100%",
                boxShadow: "0px 3px 6px #0000001F",
              }}
            >
              <MainPanelHeader
                arr={arr}
                first={arr[0]?.name}
                onSelectedChange={handleSelectedTab}
                page={"contact-details"}
              />
              {useHasAccessToFeature("F034", "P000000003") &&
                selectedTab === "Details" && (
                  <DetailsTab
                    data={contactDetails}
                    latestUpdateTime={latestDetailUpdateTime}
                    modifiedBy={contactDetails?.modifiedBy}
                    getAllData={fetchContactData}
                  />
                )}
              {useHasAccessToFeature("F035", "P000000003") &&
                selectedTab === "Projects" && (
                  <ProjectsTab
                    data={contactProjects}
                    modifiedBy={latestProjectUpdateTime?.modifiedBy}
                    latestUpdateTime={latestProjectUpdateTime?.difference}
                  />
                )}
              {useHasAccessToFeature("F036", "P000000003") &&
                selectedTab === "R&D Expense" && (
                  <RndExpenseTab
                    data={contactRnd}
                    modifiedBy={contactRnd?.modifiedBy}
                    latestUpdateTime={latestRnDUpdateTime?.difference}
                  />
                )}
              {useHasAccessToFeature("F037", "P000000003") &&
                selectedTab === "Wages" && (
                  <SalaryTab
                    currencySymbol={currencySymbol}
                    currency={currency}
                    data={contactSalary}
                    modifiedBy={contactSalary?.modifiedBy}
                    latestUpdateTime={latestSalaryUpdateTime}
                  />
                )}
            </Paper>
          </Box>
        </Box>
      </Box>
      <Toaster />
    </>
  );
}

export default ContactDetails;
