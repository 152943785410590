
import React, { createContext, useState, useEffect } from "react";
import axios from "axios";
import { BaseURL } from "../constants/Baseurl";
import { Authorization_header } from "../utils/helper/Constant";
import { useAuthContext } from "./AuthProvider";
import toast from "react-hot-toast";
import usePinnedData from "../components/CustomHooks/usePinnedData";

export const DocumentContext = createContext();

export const DocumentProvider = ({ children }) => {
  const [documents, setDocuments] = useState([]);
  const [docFilterState, setDocFilterState] = useState({
    companyId: [],
    projectId: "",
    document: "",
    company: "",
    project: "",
    sortField: "",
    sortOrder: "",
  });
  const [clearFilterTrigger, setClearFilterTrigger] = useState(false);
  const [isDocFilterApplied, setIsDocFilterApplied] = useState(false);
  const { pinnedObject } = usePinnedData();
  const [currentState, setCurrentState] = useState(
    pinnedObject?.DOCUMENTS === "RV" ? "Recently Viewed" : "All Documents"
  );

  const triggerClearFilters = () => {
    setClearFilterTrigger(!clearFilterTrigger);
    setIsDocFilterApplied(false);
  };
  const { logout } = useAuthContext();

  function getAccessToken() {
    const tokens = localStorage.getItem('tokens');
    const token_obj = JSON.parse(tokens);
    return token_obj?.accessToken || '';
  }

  const documentSort = async ({ sortField, sortOrder }) => {
    const url = `${BaseURL}/api/v1/documents/${localStorage.getItem("userid")}/get-docs`;
    try {
      toast.loading("Fetching projects data");
      const config = {
        headers: {
          Authorization: `Bearer ${getAccessToken()}`,
        },
        params: {
          sortField: sortField,
          sortOrder: sortOrder,
        },
      };
      const response = await axios.get(url, config)
      toast.dismiss();
      setDocuments(response?.data?.data);
      toast.success(response?.data?.message || "Succesfully fetched data");
    } catch (error) {
      toast.dismiss();
      toast.error("Error in fetching Timesheet data");
      console.error("Error in fetching client data : ", error);
    }
  }

  const fetchDocuments = async (options = {}) => {
    const queryParams = new URLSearchParams();
    setIsDocFilterApplied(!!JSON.stringify(options));

    const company = options.relatedTo === "clients" ? options.relationId : null;
    if (options.relatedTo === "clients" && options.companyIds && options.companyIds[0]) {
      queryParams.append("companyIds", JSON.stringify(options.companyIds));
    } else {
      // Add other filters for non-client related queries
      if (options.companyIds && options.companyIds[0]) {
        queryParams.append("companyIds", JSON.stringify(options.companyIds));
      }
      if (options.projectId) {
        queryParams.append("projectId", options.projectId);
      }
      if (options.documentType) {
        queryParams.append("documentType", options.documentType);
      }
      options?.sortField?.length && queryParams.append("sortField", options.sortField);
      options?.sortOrder?.length && queryParams.append("sortOrder", options.sortOrder);
      company && queryParams.append("company", company); 
    }

    // Additional filtering conditions can be added here as needed
    if (options.documentId) queryParams.append("documentId", options.documentId);
    if (options.relatedTo && options.relatedTo !== "clients") queryParams.append("relatedTo", options.relatedTo);
    if (options.relationId && options.relatedTo !== "clients") {
      queryParams.append("relationId", options.relationId);
      queryParams.append("project", options.relationId);
    }
    if (currentState === "Recently Viewed")
      queryParams.append("recentlyViewed", true);

    // Convert query params into query string
    const queryString = queryParams.toString();

    // Construct the URL with query parameters
    
    const url = `${BaseURL}/api/v1/documents/${localStorage.getItem("userid")}/get-docs${queryString ? `?${queryString}` : ""}`;

    try {
      const response = await axios.get(url, Authorization_header());
      toast.dismiss();

      setDocuments(response?.data?.data);

    } catch (error) {
      toast.dismiss()
      if (error?.response?.data?.logout === true || error?.response?.data?.message === "session timed out") {
        toast.error("Session expired, you need to login again");
        logout();
        return;
      }
      toast.error("Error in fetching Documents!");
      console.error("Failed to fetch documents:", error);
    }
  };

  useEffect(() => {
    fetchDocuments();
  }, []);

  return (
    <DocumentContext.Provider
      value={{
        documents,
        fetchDocuments,
        docFilterState,
        setDocFilterState,
        triggerClearFilters,
        clearFilterTrigger,
        isDocFilterApplied,
        setIsDocFilterApplied,
        setCurrentState,
        documentSort
      }}
    >
      {children}
    </DocumentContext.Provider>
  );
};
