import axios from "axios";
import React, { createContext, useState } from "react";
import usePinnedData from "../components/CustomHooks/usePinnedData";
import { BaseURL } from "../constants/Baseurl";
import { Authorization_header } from "../utils/helper/Constant";
import { useAuthContext } from "./AuthProvider";
import toast from "react-hot-toast";

export const TimesheetContext = createContext();

export const TimesheetProvider = ({ children }) => {
  const { pinnedObject } = usePinnedData();
  const [timesheets, setTimesheets] = useState([]);
  const [timesheetFilterState, setTimesheetFilterState] = useState({
    companyId: [],
    company: "",
    accountingYear: [],
    accYear: "",
    totalhours: [0, 2000000],
    sortOrder: "",
    sortField: ""
  });
  const [clearTimesheetFilterTrigger, setClearTimesheetFilterTrigger] =
    useState(false);
  const [isTimesheetFilterApplied, setIsTimesheetFilterApplied] =
    useState(false);
  const [currentState, setCurrentState] = useState(
    pinnedObject?.TIMESHEETS === "RV" ? "Recently Viewed" : "All Timesheets"
  );
  const [loading, setLoading] = useState(false);
  const { logout } = useAuthContext();

  const triggerTimesheetClearFilters = () => {
    setClearTimesheetFilterTrigger((prev) => !prev);
  };

  function getAccessToken() {
    const tokens = localStorage.getItem('tokens');
    const token_obj = JSON.parse(tokens);
    return token_obj?.accessToken || '';
  }

  const timeSheetSort = async ({ sortField, sortOrder }) => {
    const url = `${BaseURL}/api/v1/timesheets/${localStorage.getItem("userid")}/1/timesheet-logs`;
    try {
      toast.loading("Fetching projects data");
      const config = {
        headers: {
          Authorization: `Bearer ${getAccessToken()}`,
        },
        params: {
          sortField: sortField,
          sortOrder: sortOrder,
        },
      };
      const response = await axios.get(url, config)
      toast.dismiss();
      setTimesheets(response?.data?.data);
      toast.success(response?.data?.message || "Succesfully fetched data");
    } catch (error) {
      toast.dismiss();
      toast.error("Error in fetching Timesheet data");
      console.error("Error in fetching client data : ", error);
    }
  }

  const fetchTimesheets = async (options = {}) => {
    setLoading(true);
    const queryParams = new URLSearchParams();
    if (options.client)
      queryParams.append("companyIds", JSON.stringify(options.client));
    if (options.accountingYear || timesheetFilterState.accountingYear.length > 0)
      queryParams.append(
        "fiscalYears",
        JSON.stringify(options.accountingYear || timesheetFilterState.accountingYear)
      );
    if (options.minTotalhours != null && options.minTotalhours > 0) {
      queryParams.append("minTotalhours", options.minTotalhours);
    }
    if (options.maxTotalhours != null && options.maxTotalhours < 2000000) {
      queryParams.append("maxTotalhours", options.maxTotalhours);
    }
    if (options?.sortField && options?.sortField != null && options?.sortOrder && options?.sortOrder != null) {
      queryParams.append("sortField", options?.sortField);
      queryParams.append("sortOrder", options?.sortOrder);
    }
    if (currentState === "Recently Viewed")
      queryParams.append("recentlyViewed", true);

    const queryString = queryParams.toString();
    const url = `${BaseURL}/api/v1/timesheets/${localStorage.getItem(
      "userid"
    )}/1/timesheet-logs${queryString ? `?${queryString}` : ""}`;
    setIsTimesheetFilterApplied(!!queryString);

    try {
      const response = await axios.get(url, Authorization_header());
      setTimesheets(response?.data?.data);
      setLoading(false);
    } catch (error) {
      console.error("error", error);
      if (error?.response?.data?.logout === true || error?.response?.data?.message === "session timed out") {
        toast.error("Session expired, you need to login again");
        logout();
      }
      setLoading(false);
      console.error(error);
    }
  };

  return (
    <TimesheetContext.Provider
      value={{
        timesheetFilterState,
        setTimesheetFilterState,
        timesheets,
        fetchTimesheets,
        triggerTimesheetClearFilters,
        clearTimesheetFilterTrigger,
        isTimesheetFilterApplied,
        setIsTimesheetFilterApplied,
        currentState,
        setCurrentState,
        timeSheetSort,
        loading
      }}
    >
      {children}
    </TimesheetContext.Provider>
  );
};
