import React from "react";
import FilterCustomAutocomplete from "../Common/FilterCustomAutocomplete";

function LocationFilter({ billingCountry, billingCountryList, setBillingCountry, }) {
  return (
    <>
      <FilterCustomAutocomplete
        label=""
        placeholder="Select Billing Country"
        options={billingCountryList?.map((billingCountry) => billingCountry) ?? []}
        value={billingCountry}
        onChange={(event, newValue) => {
          setBillingCountry(newValue ?? "");
        }}
        inputValue={billingCountry}
        onInputChange={(event, newInputValue) => {
          setBillingCountry(newInputValue ?? "");
        }}
        heading={"Billing Country"}
      />
    </>
  );
}

export default LocationFilter;
